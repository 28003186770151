import { FormGroup } from '@angular/forms';
import { UtilService } from 'src/app/util/services/util.service';
import { Injectable } from '@angular/core';
import { SourcingService } from './sourcing.service';

@Injectable({
  providedIn: 'root'
})
export class SourcingUtilService {
  static readonly cpt_types = [
    { 'value': 'ALL', 'name': 'All' },
    { 'value': 'CPT', 'name': 'CPT' },
    { 'value': 'NON_CPT', 'name': 'Non CPT' },
  ];


  static progressBar = { color: 'primary', indeterminate: 'indeterminate', value: "50", bufferValue: "70" };

  constructor(private utilService: UtilService) { }

  cleanseResponse(response, successFlag?, message?) {
    if (response['success']) {
      if (successFlag) {
        this.utilService.openToaster({ message: response['responseMessage'], messageType: 'SUCCESS' });
        return true;
      }
      return response['data'];
    } else {
      this.utilService.openToaster({ message: (response['responseMessage']) ? response['responseMessage'] : message, messageType: 'ERROR' });
    }
    return null;
  }

  // getPageOptions(pageMultiple, totalRecords, maxPageSize)
  // {
  //   let pageLength = Math.floor((totalRecords / pageMultiple));
  //   let pageOptions = [];
  //   if (totalRecords >= maxPageSize) {
  //     pageLength = (maxPageSize) / pageMultiple;
  //   }
  //   for (let page = 1; page <= pageLength - 1; page++) {
  //     pageOptions.push((pageMultiple * page) + pageMultiple);
  //   }
  //   return pageOptions;
  // }


  getPageOptions(pageMultiple, totalRecords, maxPageSize) {
    let pageOptions = [];
    if (totalRecords <= 10) {
      pageOptions = [10];
    } else if (totalRecords > 10 && totalRecords <= 15) {
      pageOptions = [10, 15];
    } else if (totalRecords > 15 && totalRecords <= 20) {
      pageOptions = [10, 15, 20];
    } else if (totalRecords > 20 && totalRecords <= 25) {
      pageOptions = [10, 15, 20, 25];
    } else if (totalRecords > 25 && totalRecords <= 50) {
      pageOptions = [10, 15, 20, 25, 50];
    } else {
      pageOptions = [10, 15, 20, 25, 50, 100];
    }
    return pageOptions;
  }


  ellipse(value: string, limit: number) {
    return value.length > limit ? value.slice(0, limit) + '...' : value;
  }

  copyText(val: string) {
    let copyBox = document.createElement('textarea');
    copyBox.value = val;
    document.body.appendChild(copyBox);
    copyBox.select();
    document.execCommand('copy');
    document.body.removeChild(copyBox);
  }

  displaySupplier = (supplier): string => {
    let returnValue: string = '';
    if (supplier) {
      let supplierName: string = supplier ? supplier.supplierName : '';
      let supplierCPT: string = supplier.cptType ? supplier.cptType : '';
      returnValue = supplierName + ' ' + supplierCPT.charAt(0) + supplierCPT.charAt(1).toUpperCase() + supplierCPT.slice(2);
    }
    return returnValue.trim();
  }

  getCriteria(criteriaForm:FormGroup)
  {
    let criteria = {};
    Object.keys(criteriaForm.value).forEach((key) =>
    {
      if (criteriaForm.value[key]) {
        if (typeof criteriaForm.value[key] == 'object') {
          if(key === 'plant'){
            criteria[key + 'Id'] = criteriaForm.value[key]['idPlant'];
          }else if(key === 'team'){
            criteria[key + 'Id'] = criteriaForm.value[key]['idTeam'];
          }else if(key === 'search' && criteriaForm.value[key]['searchValue']){
            criteria[key] = criteriaForm.value[key];
          }else{
            criteria[key + 'Id'] = criteriaForm.value[key][key + 'Id'];
          }
        } else {
            criteria[key] = criteriaForm.value[key]
        }
      }
    })
    return criteria;
  }

  displayTeamName = (teamName) => { return teamName ? teamName.name : null; }
  displayPlantName = (plantName) => { return plantName ? '(' + plantName.idPlant + ') ' + plantName.name : null; }
  displayError = (message) => { this.utilService.openToaster({ message: message, messageType: 'ERROR' }); }
  getCPTTypes = () => { return SourcingUtilService.cpt_types; }
  getProgressBar = () => { return SourcingUtilService.progressBar; }
  display = (flag) => { return { 'display': flag ? 'block' : 'none' }; }
}

import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UserService } from '../user.service';
import { FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatAutocompleteSelectedEvent, MatDialogRef } from '@angular/material';
@Component({
	selector: 'app-dialog-box',
	templateUrl: './dialog-box.component.html',
	styleUrls: ['./dialog-box.component.scss']
})
export class DialogBoxComponent implements OnInit {
	[x: string]: any;
	showMe: boolean = false;
	description: string;
	subject: string;
	email1: any;
	email: string;
	email2: any;
	username: any;
	password: any;
	requesterTeamData: any;
	requester_team: any;
	regionData: any;
	supportTeamData: any;
	support_team: any = "Tech Support Team";
	region: any;
	issueType: any;
	issue: any;
	bulkinvoice: any;
	bulkinvoiceChoices: any;
	bulkinvoiceChoices1: any;
	bulkinvoiceChoices2: any;
	creation: any;
	techissue: any;
	buyertechissue: any;
	emstechissue: any;
	financeissue: any;
	omstechissue: any;
	eoctechissue: any;
	wmstechissue: any;
	scmtechissue: any;
	runnertechissue: any;
	bulksub1: any;
	accesssub1: any;
	businessUnit: any;
	analyticsOption: any;
	functions: any;
	functionData: any;
	warehouseName: any;
	role: any;
	accessRoleData: any;
	subType5: any;
	creationData: any;
	page: string;
	accessTeamName: any;
	omsAccessRole: any;
	accessPlantId: any;
	accessIssue: any;
	fetchUrl: string;
	business: any;
	plantId: any;
	accessSub3: any;
	function: any;
	warehouse_name: any;
	techsub1: any;
	file_name: any;
	file: File;
	form = this.fb.group({
		file_upload: null,
	});
	public readonly BASE_ACCOUNT_SERVICE_URL = environment.ACCOUNT_SERVICE_URL;
    separatorKeysCodes: number[] = [ENTER, COMMA];
	cc_emails = new FormControl();
    mail = [];
    mailCC: string[] = [];
	ccEmailArr: any;

	@ViewChild('emailInput') emailInput: ElementRef<HTMLInputElement>;
	accessSub2: any;
	fileName = '';

	constructor(private http: HttpClient,
		public userService: UserService, private activatedRoute: ActivatedRoute,
		public router: Router,
		public toastr: ToastrService, private fb: FormBuilder,
		public dialogRef: MatDialogRef<DialogBoxComponent>,

	) {
		this.fetchUrl = window.location.href;
	}

	remove(x: string): void {
		const index = this.mail.indexOf(x);
		if (index >= 0) {
			this.mail.splice(index, 1);
		}
	}

	selected(event: MatAutocompleteSelectedEvent): void {
		this.mail.push(event.option.viewValue);
		this.emailInput.nativeElement.value = '';
		this.cc_emails.setValue(event.option.value);
	}

	private _filter(value: string): string[] {
		const filterValue = value.toLowerCase();

		return this.mailCC.filter(x => x.toLowerCase().includes(filterValue));
	}

    ngOnInit() {
		let emailId = this.userService.getValue('email').split(' ')[0];
		this.email = emailId;
		let username = "POnL4iLubnZl1NpPadVK";
		let password = "X";
		let authorizationData = 'Basic ' + btoa(username + ':' + password);
		this.http.get("https://moglilabs.freshservice.com/api/v2/ticket_form_fields", {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				'Authorization': authorizationData
			})
		}).subscribe((data) => {
			this.supportTeamData = data["ticket_fields"].find(item => item.label == "Support Team").choices;
			this.requesterTeamData = data["ticket_fields"].find(item => item.label == "Requester Team").choices;
			this.regionData = data["ticket_fields"].find(item => item.label == "Requester Team").choices.find(item => (item.value == "Enterprise" || item.value == "Warehouse")).nested_options;
			this.issueType = data["ticket_fields"].find(item => item.label == "Issue Type").choices;
			this.accessIssue = data["ticket_fields"].find(item => item.label == "Issue Type").choices.find(item => item.value == "Access Request").nested_options;
			this.bulkinvoice = data["ticket_fields"].find(item => item.label == "Issue Type").choices.find(item => item.value == "Bulk Invoicing Issue").nested_options;
			this.bulkinvoiceChoices = data["ticket_fields"].find(item => item.label == "Issue Type").choices.find(item => item.value == "Bulk Invoicing Issue").nested_options.find(item => item.value == "EMS").nested_options;
			this.bulkinvoiceChoices1 = data["ticket_fields"].find(item => item.label == "Issue Type").choices.find(item => item.value == "Bulk Invoicing Issue").nested_options.find(item => item.value == "SCM").nested_options;
			this.bulkinvoiceChoices2 = data["ticket_fields"].find(item => item.label == "Issue Type").choices.find(item => item.value == "Bulk Invoicing Issue").nested_options.find(item => item.value == "EOC").nested_options;
			this.creationData = data["ticket_fields"].find(item => item.label == "Issue Type").choices.find(item => item.value == "Creation Request").nested_options;
			this.techissue = data["ticket_fields"].find(item => item.label == "Issue Type").choices.find(item => item.value == "Tech Issue").nested_options;
			this.buyertechissue = data["ticket_fields"].find(item => item.label == "Issue Type").choices.find(item => item.value == "Tech Issue").nested_options.find(item => item.value == "Buyers").nested_options;
			this.emstechissue = data["ticket_fields"].find(item => item.label == "Issue Type").choices.find(item => item.value == "Tech Issue").nested_options.find(item => item.value == "EMS").nested_options;
			this.financeissue = data["ticket_fields"].find(item => item.label == "Issue Type").choices.find(item => item.value == "Tech Issue").nested_options.find(item => item.value == "EMS (Finance)").nested_options;
			this.eoctechissue = data["ticket_fields"].find(item => item.label == "Issue Type").choices.find(item => item.value == "Tech Issue").nested_options.find(item => item.value == "EOC").nested_options;
			this.omstechissue = data["ticket_fields"].find(item => item.label == "Issue Type").choices.find(item => item.value == "Tech Issue").nested_options.find(item => item.value == "OMS").nested_options;
			this.runnertechissue = data["ticket_fields"].find(item => item.label == "Issue Type").choices.find(item => item.value == "Tech Issue").nested_options.find(item => item.value == "Runner / SupplyChain").nested_options;
			this.scmtechissue = data["ticket_fields"].find(item => item.label == "Issue Type").choices.find(item => item.value == "Tech Issue").nested_options.find(item => item.value == "SCM / Salesops").nested_options;
			this.wmstechissue = data["ticket_fields"].find(item => item.label == "Issue Type").choices.find(item => item.value == "Tech Issue").nested_options.find(item => item.value == "WMS").nested_options;
			this.runnertechissue = data["ticket_fields"].find(item => item.label == "Issue Type").choices.find(item => item.value == "Tech Issue").nested_options.find(item => item.value == "Runner / SupplyChain").nested_options;
			this.businessUnit = data["ticket_fields"].find(item => item.label == "Business Unit").choices;
			this.functionData = data["ticket_fields"].find(item => item.label == "Functions").choices;
			this.accessRoleData = data["ticket_fields"].find(item => item.label == "Role").choices;
			this.accessTeamName = data["ticket_fields"].find(item => item.label == "Team Name").choices;
			this.omsAccessRole = data["ticket_fields"].find(item => item.label == "OMS Role").choices;
			this.accessPlantId = data["ticket_fields"].find(item => item.label == "Plant ID").choices;
		});
	}
	getccMail() {
		this.http.get(this.BASE_ACCOUNT_SERVICE_URL + "account/user/search-email?key=" + this.cc_emails.value).subscribe((data) => {
			this.ccEmailArr = data["data"];
			this.ccEmailArr = this.ccEmailArr.map((i: Number) => { return { email: i } });
			if (this.ccEmailArr.length == 0) {
				// this.cc_emails.reset();
				this.toastr.warning('Please Enter Valid Email.')
			}


		})

	}
	onFileSelected(event) {
		const reader = new FileReader();

		if (event.target.files && event.target.files.length > 0) {
			this.file = event.target.files[0];
			this.successUpload = true;
			this.fileName = this.file.name;
		}


	}

	toggleMe() {
		this.showMe = !this.showMe
	}

	onSubmit() {
        if (!this.requester_team) {
			this.toastr.error("Please select Requester Team.");
			return;
		} else if (!this.subject) {
			this.toastr.error("Please select Subject.");
			return;
		} else if (!this.issue) {
			this.toastr.error("Please select Issue.");
			return;
		} else if (this.issue == "Creation Request" && !this.creation) {
			this.toastr.error("Please select Creation.");
			return;
		}
		else if (this.issue == "Access Request" && !this.accesssub1) {
			this.toastr.error("Please select Subtype1.");
			return;
		} else if (this.issue == "Bulk Invoicing Issue" && !this.bulksub1) {
			this.toastr.error("Please select Subtype1.");
			return;
		} else if (this.issue == "Tech Issue" && !this.techsub1) {
			this.toastr.error("Please select Subtype1.");
			return;
		} else if (!this.description) {
			this.toastr.error("Please select Description.");
			return;
		} if (this.cc_emails.value != "") {
			if (this.ccEmailArr != undefined) {
				if (this.ccEmailArr.length == 0) {
					// this.cc_emails.reset();
					this.toastr.warning('Please Enter Valid Email.');
					return
				}
			}
		} 

		const body = new FormData();
		body.append('description', this.description);
		body.append('subject', this.subject);
		body.append('email', this.email);
		if (this.cc_emails.value) {
			body.append('cc_emails[]', this.cc_emails.value);
		}
		body.append('custom_fields[requester_team]', this.requester_team);
		body.append('custom_fields[request_issue]', this.issue);
		body.append('custom_fields[portal_url]', this.fetchUrl);
		body.append('custom_fields[support_team]', this.support_team);
		body.append('custom_fields[sub1]', this.bulksub1 || this.accesssub1 || this.techsub1 || this.creation);
		body.append('custom_fields[warehouse_name]', this.warehouse_name);
		if (this.file) {
			body.append('attachments[]', this.file);
		}
		if (this.business) {
			body.append('custom_fields[msf_business_unit]', this.business);
		}
		if (this.plantId) {
			body.append('custom_fields[plant]', this.plantId);
		}
		if (this.function) {
			body.append('custom_fields[msf_functions]', this.function);
		}
		if (this.accessSub2) {
			body.append('custom_fields[msf_role]', this.accessSub2);
		}
		if (this.team) {
			body.append('custom_fields[team_name]', this.team);
		}
		if (this.accessSub2) {
			body.append('custom_fields[oms_ro]', this.accessSub2);
		}
		if (this.mobile) {
			body.append('custom_fields[mobile_number]', this.mobile);
		}
		if (this.imei) {
			body.append('custom_fields[imei_1]', this.imei);
		}
		if (this.imei_2) {
			body.append('custom_fields[imei_2]', this.imei_2);
		}
		if (this.techsub2) {
			body.append('custom_fields[issue_subtype2]', this.techsub2);
		}
		if (this.region) {
			body.append('custom_fields[warehouse]', this.region);
		}

        let username = "POnL4iLubnZl1NpPadVK";
		let password = "X";
        let authorizationData = 'Basic ' + btoa(username + ':' + password);
        this.http.post('https://moglilabs.freshservice.com/api/v2/tickets', body, {
			headers: new HttpHeaders({
				'Access-Control-Expose-Headers': 'Content-Disposition',
				'Authorization': authorizationData
			})
		}).subscribe((data) => {
			this.toastr.success("Ticket is created sucessfully!")
			this.dialogRef.close();
		});
	}

	onNoClick(): void {
		this.dialogRef.close();
	}

}
import { AppInterceptor } from './../app.interceptor';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfirmationComponent } from './confirmation/confirmation.component';
import { ToasterComponent } from './toaster/toaster.component';
import { UtilService } from './services/util.service';
import { MatButtonModule, MatSnackBarModule, MatDialogModule, MatIconModule } from '@angular/material';

@NgModule({
  declarations: [ConfirmationComponent, ToasterComponent],
  imports: [
    CommonModule,
    HttpClientModule,
    MatButtonModule,
    MatSnackBarModule,
    MatDialogModule,
    MatIconModule,
  ],
  entryComponents:[ToasterComponent,  ConfirmationComponent],
  providers:[UtilService,{
    provide: HTTP_INTERCEPTORS,
    useClass: AppInterceptor,
    multi: true
  },]
})
export class UtilModule { }

import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';


const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
@Injectable({
  providedIn: 'root',
})
export class DataService {
  constructor(private http: HttpClient) {}

  get(url, headersJson?: any) {
    return this.http.get(url, headersJson);
  }

  post(url, resource, headersJson?: any) {
    return this.http.post(url, resource, headersJson);
  }

  put(url, resource, headersJson?: any) {
    return this.http.put(url, resource, headersJson);
  }

  patch(url, resource, headersJson?: any) {
    return this.http.patch(url, resource, headersJson);
  }

  delete(url, id, headersJson?: any) {
    return this.http.delete(url + id, headersJson);
  }

  getImage(imageUrl: string): Observable<Blob> {
    return this.http.get(imageUrl, { responseType: 'blob' });
  }
  downloadPDF(invoiceUrl){
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/pdf');
    return this.http.get(invoiceUrl, {responseType: 'blob' });
   }   
}

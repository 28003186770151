import { ErrorHandler, Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';

@Injectable()
export class AppErrorHandler implements ErrorHandler {
    handleError(error: Error | HttpErrorResponse) {
        if (error instanceof HttpErrorResponse) {
            //  alert('Server is not responding');
            console.log('This error is due to backend issue');
        }
        console.log(error);
    }
}
<nav class="navbar">
  <!-- <app-structure-side-nav-toggler></app-structure-side-nav-toggler> -->
    <!-- <h3>Unified Portal</h3> -->
    <img class="moglix-logo" src="/assets/logo.png" routerLink="/salesops" style="cursor: pointer; margin-left: 15px;">
    <!-- <div class="breadcrumb">
      <span class="pagename">Sourcing</span><span class="pagename active">Orders</span>
    </div> -->
    <ul class="topmenuright">
      <!-- <li>
        <div><span class="fonticon"><i class="ri-notification-3-line"></i><span class="notify"></span></span></div>
      </li>
      <li>
        <div><span class="fonticon"><i class="ri-chat-4-line"></i><span class="notify"></span></span></div>
      </li> -->
      <li>
        <div class="submit-ticket-btn">
          <!--<button class="button_click" title="Help" style="z-index:0;position:fixed;" (click)="openDialog()"><img src="assets/img/helpdesk.png"/> </button>-->
          <img class="submit-ticket-img" id="submit_ticket_btn" title="Help" (click)="submitTicketDialog()" src="assets/img/helpdesk.png" />
        </div>
      </li>
      <li>
        <div>
          <span class="firstandlastname">{{firstName + lastName}}</span>
          <div class="countryImg">
            <img [src]="getCountryFlag(countryName)" />
            
            <div class="view-mobile">
          <img [src]="getCountryFlag(countryName)" />
          <span *ngIf = "!userService.checkMediaScreen && !userService.getIsMobileView" class="country-name-mobile">{{countryName}}</span>
        </div>
          <strong class="ml-10" *ngIf="countryName != 'United Arab Emirates (the),'"> <span class="country-name-desktop">{{countryName}}</span>  {{userService.getValue('name')}} </strong>
          <strong class="ml-10" *ngIf="countryName == 'United Arab Emirates (the),'"> UAE {{userService.getValue('name')}} </strong>
        </div>
          <button mat-icon-button [matMenuTriggerFor]="appMenu">
              <i class="ri-more-2-fill"></i>
          </button>          
          <mat-menu #appMenu="matMenu" class="topdropmenu">
              <button mat-menu-item (click)="signOut()">Logout</button>
              <!-- <button mat-menu-item>Help</button> -->
          </mat-menu>
        </div>
      </li>
    </ul>
</nav>

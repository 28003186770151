import { ReturnsComponent } from './returns/returns.component';
import { ReportsComponent } from './reports/reports.component';
import { ApComponent } from './ap/ap.component';
import { AuthGuard } from './../auth/auth.guard';
import { ArComponent } from './ar/ar.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [{
  path: 'ar',
  component: ArComponent,
  canActivate: [AuthGuard]
},{
  path: 'ap',
  component: ApComponent,
  canActivate: [AuthGuard]
},{
  path: 'reports',
  component: ReportsComponent,
  canActivate: [AuthGuard]
},{
  path: 'returns',
  component: ReturnsComponent,
  canActivate: [AuthGuard]
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FinanceRoutingModule { }

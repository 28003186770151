import { AuthGuard } from './auth/auth.guard';
import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { DesignComponent } from './structure/design/design.component';

const routes: Routes = [
  {
    path: 'design',
    loadChildren: './structure/dashboard.module#DashboardModule',
    canActivate: [AuthGuard],
  },
  {
    path: 'login',
    loadChildren: './auth/auth.module#AuthModule',
  },
  {
    path: 'salesops',
    loadChildren: './salesops/salesops.module#SalesopsModule',
    canActivate: [AuthGuard],
  },
  {
    path: 'warehousemanagement',
    loadChildren: './warehousing/warehousing.module#WarehousingModule',
    canActivate: [AuthGuard],
  },
  {
    path: 'sourcing',
    loadChildren: './sourcing/sourcing.module#SourcingModule',
    canActivate: [AuthGuard],
  },
  {
    path: 'sourcing/:tabId',
    loadChildren: './sourcing/sourcing.module#SourcingModule',
    canActivate: [AuthGuard],
  },
  {
    path: 'finance',
    loadChildren: './finance/finance.module#FinanceModule',
    canActivate: [AuthGuard],
  },
  {
    path: 'settings',
    loadChildren: './settings/settings.module#SettingsModule',
    canActivate: [AuthGuard],
  },
  {
    path: 'procurement',
    loadChildren: './procurement/procurement.module#ProcurementModule',
    canActivate: [AuthGuard],
  },
  {
    path: 'invoice',
    loadChildren: './invoice/invoice.module#InvoiceModule',
    canActivate: [AuthGuard]
  },
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  }
  // {
  //   path: '**',
  //   redirectTo: 'salesops'
  // }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule],
})
export class AppRoutingModule { }

//,{enableTracing:true}

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { DataService } from 'src/app/util/services/data.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-update-hsn',
  templateUrl: './update-hsn.component.html',
  styleUrls: ['./update-hsn.component.scss']
})
export class UpdateHsnComponent implements OnInit {
  readonly WMSURL = environment.WMSURL;
  hsnUpdateData: any;
  hsnCode: any;
  constructor(public dataService: DataService,
    @Inject(MAT_DIALOG_DATA) public data,
    private toastr: ToastrService,) { }

  ngOnInit() {
    this.hsnUpdateData = this.data
  }

  onHsnUpdate(){
    if (this.hsnCode == undefined || this.hsnCode == null) {
      this.toastr.warning("Please Enter HSN Code");
      return;
    }else if (this.hsnCode.toString().length == 6  || this.hsnCode.toString().length == 8) {
    let request ={
        "inboundId": this.hsnUpdateData.id, 
        "productMsn": this.hsnUpdateData.productMsn,
        "hsnCode": this.hsnCode,
        "supplierId": this.hsnUpdateData.supplierId,
        "supplierPoId": this.hsnUpdateData.supplierPoId,
        "supplierPoItemId": this.hsnUpdateData.supplierPoItemId,
        "warehouseId": this.hsnUpdateData.warehouseId,
        "pickUpWarehouseId":this.hsnUpdateData.pickUpWarehouseId
      }
      this.dataService.post(this.WMSURL + 'invoice/updateHSNCode', request).subscribe(
        (data)=>{
         if(data['status'] == true){
          this.toastr.success(data['message']);
         }
        }
      )
    }else  {
      this.toastr.warning("Please Enter Valid HSN Code");
    }
  }

}

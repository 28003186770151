import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/user.service';
import { Router } from '@angular/router';
import { DialogBoxComponent } from 'src/app/dialog-box/dialog-box.component';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'app-structure-top-nav',
  templateUrl: './structure-top-nav.component.html',
  styleUrls: ['./structure-top-nav.component.scss']
})
export class StructureTopNavComponent implements OnInit {

  constructor(private router:Router,public dialog: MatDialog, public userService: UserService) { }
countryName:string;
submitTicketDialog(){
      
  this.dialog.open(DialogBoxComponent,{
    backdropClass: 'submit-ticket-dialog-backdrop-class', 
panelClass: 'submit-ticket-dialog-panel-class',  
  }) 
}
  ngOnInit() {
    this.getFirstandLastname()
    this.countryName= this.userService.getSelectedCountryName();

  }

  signOut(){
    this.userService.signOut();
    this.router.navigate(['login']);
  }

  firstName = ''
  lastName = ''
  getFirstandLastname(){
    let x = this.userService.getValue('name').split(' ')
    this.firstName = x[0][0]
    this.lastName = x[1][0]
    console

  }
  getCountryFlag(img){
    return "assets/img/"+img+".svg";
  }
}

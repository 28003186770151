import { ToastrModule } from 'ngx-toastr';
import { StructureSideNavTogglerComponent } from './structure/structure-side-nav-toggler/structure-side-nav-toggler.component';
import { StructureTopNavComponent } from './structure/structure-top-nav/structure-top-nav.component';
import { StructureSideNavComponent } from './structure/structure-side-nav/structure-side-nav.component';
import { ReportsComponent } from './finance/reports/reports.component';
import { ApComponent } from './finance/ap/ap.component';
import { ArComponent } from './finance/ar/ar.component';
import { FinanceRoutingModule } from './finance/finance-routing.module';
import { MatDividerModule } from '@angular/material/divider';
import { MatButtonModule } from '@angular/material/button';
import { AuthGuard } from './auth/auth.guard';
import { UtilModule } from './util/util.module';
import { UserService } from './user.service';
import { MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule, ErrorHandler } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSelectModule } from '@angular/material/select';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
// import { SocialLoginModule, SocialAuthServiceConfig } from "angularx-social-login";
// import { GoogleLoginProvider, MicrosoftLoginProvider } from "angularx-social-login";
import { environment } from './../environments/environment';
import { AppErrorHandler } from './app.errorhandler';
import { MatDatepickerModule, MatIconModule } from '@angular/material';
import { ReturnsComponent } from './finance/returns/returns.component';
import { DesignComponent } from './structure/design/design.component';
import { DashboardModule } from './structure/dashboard.module';
import { NgHttpLoaderModule } from 'ng-http-loader';
import { InProgressDetailComponent } from './warehousing/warehousing/inventory/inventorytransfer/in-progress/in-progress-detail.component';
import { MatTableModule } from '@angular/material/table';
import { MatFormFieldModule, MatInputModule } from '@angular/material';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MatMomentDateModule } from '@angular/material-moment-adapter';
import { BinDetailsComponent } from './warehousing/warehousing/inventory/binDetailsUpload/bin-details.component';
import { WarehousingModule } from './warehousing/warehousing.module';
import { InventorymsnDetailDialogComponent } from './warehousing/warehousing/inventory/inventoryview/inventorymsn-detail-dialog/inventorymsn-detail-dialog.component';
import { BarcodeDetailComponent } from './warehousing/warehousing/inventory/inventory-dialog/barcode-detail.component';
import { AngularFileUploaderModule } from 'angular-file-uploader';
import { MatChipsModule } from '@angular/material/chips';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { DialogBoxComponent } from './dialog-box/dialog-box.component';
import { UpdateHsnComponent } from './warehousing/warehousing/inbound/inbounding/update-hsn/update-hsn.component';
import { MsalModule, MsalService, MSAL_INSTANCE } from '@azure/msal-angular';
import { IPublicClientApplication, PublicClientApplication } from '@azure/msal-browser';
import { StInTransitInvoicePopupComponent } from './warehousing/warehousing/STN/st-in-transit-invoice-popup/st-in-transit-invoice-popup.component';
import { InFlightInventoryComponent } from './warehousing/warehousing/inventory/InFlightInventory/in-flight-inventory.component';
import { ActiveSpoListComponent } from './warehousing/warehousing/inventory/InFlightInventory/ActiveSpoList/active-spo-list/active-spo-list.component';

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.MICROSOFT_CLIENT_ID, //application client id
      redirectUri: environment.MICROSOFT_REDIRECT_URI,
      postLogoutRedirectUri: environment.MICROSOFT_REDIRECT_URI,
      authority: environment.MICROSOFT_AUTHORITY_URI, //added tenant id,
      navigateToLoginRequestUrl: true
    },
    cache: {
      cacheLocation: 'localStorage', // This configures where your cache will be stored
      storeAuthStateInCookie: true // Set this to "true" if you are having issues on IE11 or Edge
    }
  });
}

// let config = new SocialAuthServiceConfig([
//   {
//     id: GoogleLoginProvider.PROVIDER_ID,
//     provider: new GoogleLoginProvider(environment.CLIENTID)
//   },
//   {
//     id: MicrosoftLoginProvider.PROVIDER_ID,
//     provider: new MicrosoftLoginProvider(environment.MICROSOFT_CLIENT_ID)
// }
// ]);

// export function provideConfig() {
//   return config;
// }

@NgModule({
  declarations: [
    AppComponent,
    ArComponent,
    ApComponent,
    ReportsComponent,
    ReturnsComponent,
    InProgressDetailComponent,
    InventorymsnDetailDialogComponent,
    BarcodeDetailComponent,
    DialogBoxComponent,
    UpdateHsnComponent,
    StInTransitInvoicePopupComponent,
    // ActiveSpoListComponent,
    // InFlightInventoryComponent
    // DesignComponent,
    // StructureSideNavComponent,
    // DesignComponent,
    // StructureTopNavComponent,
    // StructureSideNavTogglerComponent
  ],
  imports: [

    MatDialogModule,
    MatChipsModule,
    DashboardModule,
    MatSelectModule,
    MatDatepickerModule, MatMomentDateModule,
    BrowserModule,
    MatTableModule,
    MatFormFieldModule,
    MatInputModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    AppRoutingModule,
    MatButtonModule,
    MatIconModule,
    MatDividerModule,
    MatAutocompleteModule,
    // SocialLoginModule,
    AngularFileUploaderModule,
    FinanceRoutingModule,
    NgHttpLoaderModule.forRoot(),
    ToastrModule.forRoot({
      preventDuplicates: true,
      positionClass: 'toast-top-center'
    }),
    UtilModule
  ],
  providers: [
    UserService,
    { provide: MatDialogRef, useValue: { InProgressDetailComponent } },
    { provide: MAT_DIALOG_DATA, useValue: [InProgressDetailComponent] },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    // { provide: HTTP_INTERCEPTORS, useClass: InterceptorsService, multi: true },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    MsalService,
    // {


    //   provide: 'SocialAuthServiceConfig',
    //   useValue: {
    //     autoLogin: false,
    //     providers: [
    //       {
    //         id: GoogleLoginProvider.PROVIDER_ID,
    //         provider: new GoogleLoginProvider(environment.CLIENTID)
    //       },
    //       {
    //         id: MicrosoftLoginProvider.PROVIDER_ID,
    //         provider: new MicrosoftLoginProvider(environment.MICROSOFT_CLIENT_ID, {
    //           authority: environment.MICROSOFT_AUTHORITY_URI
    //         })
    //       }
    //     ]
    //   }
    // },
    {




      provide: ErrorHandler,
      useClass: AppErrorHandler
    },
    DatePipe, AuthGuard],
  bootstrap: [AppComponent],
  entryComponents: [StInTransitInvoicePopupComponent, InProgressDetailComponent, InventorymsnDetailDialogComponent, BarcodeDetailComponent, DialogBoxComponent, UpdateHsnComponent]
})
export class AppModule { }


// import { MsalModule, MsalService, MSAL_INSTANCE } from '@azure/msal-angular';
// export function MSALInstanceFactory(): IPublicClientApplication {
//   return new PublicClientApplication({
//     auth: {
//       clientId: environment.microClientID, //application client id
//       // redirectUri: 'http://localhost:4200',//'https://buyersqanew.moglix.com',
//       authority: `https://login.microsoftonline.com/${environment.microTenantID}` //added tenant id
//     }
//   });
// }
//     {
//       provide: MSAL_INSTANCE,
//       useFactory: MSALInstanceFactory
//     },
//     MsalService
<div class="stn_details">
  <div class="content">
    <div class="top_header">
      <div class="left">
        <div><strong>
      {{this.productMSN}}</strong>
        </div>
        <div>{{this.productName}}</div>
      </div>
      <button mat-button mat-dialog-close>Close</button>
    </div>

    <div class="middlesection">
      <div class="tableStructure">
        <table mat-table [dataSource]="dataSource">

          <ng-container matColumnDef="transactionType">
            <th mat-header-cell *matHeaderCellDef>Transaction Type </th>
            <td mat-cell *matCellDef="let element"> {{element.transactionType}} </td>
          </ng-container>

          <ng-container matColumnDef="docType">
            <th mat-header-cell *matHeaderCellDef> Doc Type </th>
            <td mat-cell *matCellDef="let element"> {{element.docType}} </td>
          </ng-container>
          <ng-container matColumnDef="docNumber">
            <th mat-header-cell *matHeaderCellDef>Doc Number</th>
            <td mat-cell *matCellDef="let element"> {{element.docNumber}} </td>
          </ng-container>

          <ng-container matColumnDef="WarehouseId">
            <th mat-header-cell *matHeaderCellDef> WH ID</th>
            <td mat-cell *matCellDef="let element"> {{element.warehouseId}} </td>
          </ng-container>
          <ng-container matColumnDef="warehouseName">
            <th mat-header-cell *matHeaderCellDef>WH Name</th>
            <td mat-cell *matCellDef="let element"> {{element.warehouseName}} </td>
          </ng-container>
          <ng-container matColumnDef="mrnDate">
            <th mat-header-cell *matHeaderCellDef>MRN/ASN Date</th>
            <td mat-cell *matCellDef="let element"> {{element.transaction_date}}</td>
          </ng-container>
          <ng-container matColumnDef="docQty">
            <th mat-header-cell *matHeaderCellDef>Doc Qty</th>
            <td mat-cell *matCellDef="let element"> {{display(element.docQuantity)}} </td>
          </ng-container>
          <ng-container matColumnDef="spoItem">
            <th mat-header-cell *matHeaderCellDef> SPO Item ID </th>
            <td mat-cell *matCellDef="let element"> {{element.spoItemId}} </td>
          </ng-container>
          <ng-container matColumnDef="itemRef">
            <th mat-header-cell *matHeaderCellDef> Item Ref</th>
            <td mat-cell *matCellDef="let element">{{element.itemRef}}</td>
          </ng-container>
          <ng-container matColumnDef="docValue">
            <th mat-header-cell *matHeaderCellDef>Doc Value</th>
            <td mat-cell *matCellDef="let element">{{display(element.docValue)}}</td>
          </ng-container>
          <ng-container matColumnDef="user">
            <th mat-header-cell *matHeaderCellDef>User</th>
            <td mat-cell *matCellDef="let element">{{element.userName}}</td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <div class="dummyData" *ngIf="dataSource.data.length ==0">Start Filtering to get the Data</div>
      </div>
      
    </div>
  </div>

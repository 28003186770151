import { Component, OnInit,Inject } from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
@Component({
  selector: 'app-barcode-detail',
  templateUrl: './barcode-detail.component.html',
  styleUrls: ['./barcode-detail.component.scss']
})
export class BarcodeDetailComponent implements OnInit {
  barcodeHexNum: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
  ) { this.barcodeHexNum = data.data;}
    
  ngOnInit() {
  }

}

import { DataService } from '../../util/services/data.service';
import { Injectable,  } from '@angular/core';
import { environment } from 'src/environments/environment';
import { UserService } from 'src/app/user.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FormArray } from '@angular/forms';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class SourcingService {
  public readonly BASEURL = environment.SOURCINGURL;
  readonly provisionalUrl = environment.SOPURL;
  private readonly BASEEMSURL = environment.BASEEMSURL;
  public readonly BASE_ACCOUNT_SERVICE_URL = environment.ACCOUNT_SERVICE_URL;
  //assign supplier urls
  private readonly FETCHDEMANDS = 'purchase/api/v1/demand/fetch';
  // private readonly FETCHWAREHOUSES = 'purchase/api/v1/master/warehouse';
  private readonly FETCHWAREHOUSES = 'account/warehouse/ems/with-ids';
  private readonly FETCHSUPPLIERS = 'purchase/api/v1/master/suppliers';
  private readonly ASSIGNSUPPLIER = 'purchase/api/v1/supplier/assign';
  private readonly ASSIGNSUPPLIERS = 'purchase/api/v1/supplier/assign/bulk';
  //approve assignments urls
  private readonly FETCHACTIVESUPPLIERS = 'purchase/api/v1/master/suppliers/approval';
  private readonly FETCHASSIGNMENTS = 'purchase/api/v1/supplier/assignments/approval';
  private readonly FETCHPENDENCYASSIGNMENTS = 'purchase/api/v1/supplier/assignments/pendency';
  private readonly REJECTASSIGNMENT = 'purchase/api/v1/supplier/assignments/reject?id=';
  private readonly APPROVEASSIGNMENT = 'purchase/api/v1/supplier/assignments/approve?id=';
  private readonly REJECTASSIGNMENTS = 'purchase/api/v1/supplier/assignments/reject/bulk';
  private readonly APPROVEASSIGNMENTS = 'purchase/api/v1/supplier/assignments/approve/bulk';
  private readonly FETCHPAGEDSUPPLIERS = 'purchase/api/v1/master/suppliers?searchKey=';
  private readonly MASTERPOSUPPLIERS = 'purchase/api/v1/master/suppliers/schedule';
  //create po urls
  private readonly FETCHACTIVESUPPLIERSPO = 'purchase/api/v1/master/suppliers/po';
  private readonly POMASTERDATA = 'purchase/api/v1/master/sync';
  private readonly MERGEDEMANDSUPPLIERS = 'purchase/api/v1/po/mergeDemandSuppliers';
  private readonly FTECHPOASSIGNMENTS = 'purchase/api/v1/supplier/assignments';
  private readonly CREATEPO = 'purchase/api/v1/po';
  private readonly CANCELASSIGNMENT_PO = 'purchase/api/v1/supplier/assignments/cancel/';
  //commons
  private readonly TEAMNAMES = 'purchase/api/v1/master/teams';
  private readonly PLANTNAMES = 'account/team/plant-mappings/by-team-id';
  private readonly GET_COUNTRIES = 'account/team';
  private readonly GET_USER_ACCESS_TEAMS = 'account/user/access/teams';

  //Supplier Rating
  private readonly SUPPLIERRATING = 'purchase/api/v1/supplier/rating/';

  private readonly HIGHVALUEPO = 'purchase/api/v1/po/item/highvalue';
  private readonly APPROVEHIGHVALUE = 'purchase/api/v1/po/item/highvalue/approval';
  private readonly REJECT_REASON_SAVE = 'purchase/api/v1/supplier/assignments/reject';
  private readonly EDITMASTERPO = 'purchase/api/v1/masterPo/';
  private readonly getTaxPerc = 'taxRepository/gstTaxRates.json';
  private readonly GETPICKUPHISTORY = 'purchase/api/v1/po/pickup/history/';
  private readonly SACHALLAN = 'v1/sa/po/';
  private readonly USER_ACCESS_PERMISSION = 'account/user/check-module-access';
  private readonly GET_SOURCER_EMAILS = 'account/user/search-email';
  private readonly GET_CMSTAR_DETAILS = 'purchase/api/v1/supplier/cm-components';
  private readonly SUPPLIERPERFORMANCE = 'purchase/api/v1/supplier/performance';
  private readonly BRAND_DETAILS = 'purchase/api/v1/supplier/brand-nature';
  private readonly GET_AUTOFETCH_DETAILS = 'purchase/api/v1/eoc/suggest-supplier';
  private readonly GET_UNRELEASED_ORDERS = 'purchase/api/v1/po/unreleased';
  private readonly GET_UNRELEASED_ITEMS = 'purchase/api/v1/po/item/unreleased';
  private readonly GET_LDCLAUSE_LIST = 'purchase/api/v1/master/ldClauses';
  private readonly REMOVE_ITEM_ROW = 'purchase/api/v1/po/unreleased/item/remove';
  private readonly RELEASE_UNRELEASE = 'purchase/api/v1/po/release';

  constructor(private dataService: DataService, private userService: UserService, private toastrService: ToastrService) {}

  // getWarehouses() {
  //   return this.dataService.get(this.BASEURL + this.FETCHWAREHOUSES  );
  // }

  getWarehouses(data) {
    return this.dataService.post(this.BASE_ACCOUNT_SERVICE_URL + this.FETCHWAREHOUSES, data);
  }

  getWarehousesWithOutReqdata() {
    let storedCountryCode = this.userService.getSelectedCountryCode();
    const country = { countryCode: storedCountryCode };
    return this.dataService.post(this.BASE_ACCOUNT_SERVICE_URL + this.FETCHWAREHOUSES, country);
  }

  // getTeamNames() {
  //   return this.dataService.get(this.BASEURL + this.TEAMNAMES);
  // }

  checkAccessPermission(moduleName) {
    return this.dataService.get(
      this.BASE_ACCOUNT_SERVICE_URL + this.USER_ACCESS_PERMISSION + `?moduleName=${moduleName}`
    );
  }

  getTeamNames() {
    return this.dataService.get(this.BASE_ACCOUNT_SERVICE_URL + this.GET_COUNTRIES);
  }

  getUserAccessTeams() {
    return this.dataService.get(this.BASE_ACCOUNT_SERVICE_URL + this.GET_USER_ACCESS_TEAMS);
  }

  getPlantNames(teamId) {
    return this.dataService.get(this.BASE_ACCOUNT_SERVICE_URL + this.PLANTNAMES + `?idTeam=${teamId}`);
  }

  getSuppliers() {
    return this.dataService.get(this.BASEURL + this.FETCHSUPPLIERS);
  }

  getActiveSuppliers() {
    return this.dataService.get(this.BASEURL + this.FETCHACTIVESUPPLIERS);
  }

  getDemands(data, limit, offset) {
    return this.dataService.post(this.BASEURL + this.FETCHDEMANDS + '?limit=' + limit + '&offset=' + offset, data);
  }

  getAssignments(data, limit, offset) {
    return this.dataService.post(this.BASEURL + this.FETCHASSIGNMENTS + '?limit=' + limit + '&offset=' + offset, data);
  }

  getAssignmentsPendency(data, limit, offset) {
    return this.dataService.post(this.BASEURL + this.FETCHPENDENCYASSIGNMENTS + '?limit=' + limit + '&offset=' + offset, data);
  }

  reject_approve_Assignment(assignmentId, approveFlag) {
    return this.dataService.get(
      this.BASEURL + (approveFlag ? this.APPROVEASSIGNMENT : this.REJECTASSIGNMENT) + assignmentId
    );
  }

  selected_reject_approve_Assignment(assignmentId, approveFlag, remarks) {
    return this.dataService.get(
      this.BASEURL + (approveFlag ? this.APPROVEASSIGNMENT : this.REJECTASSIGNMENT) + assignmentId + '&remarks='+ remarks
    );
  }

  reject_approve_highValuePO(assignmentId, approveFlag) {
    return this.dataService.get(
      this.BASEURL + this.APPROVEHIGHVALUE + '?isApproved=' + approveFlag + '&poId=' + assignmentId
    );
  }

  rejectReasonSaveData(assignmentId, remarks){
    return this.dataService.get(
      this.BASEURL + this.REJECT_REASON_SAVE + '?id='+assignmentId+'&remarks='+ remarks
    );
  }

  getPagedSuppliers(value, limit, offset) {
    return this.dataService.get(
      this.BASEURL + this.FETCHPAGEDSUPPLIERS + value + '&limit=' + limit + '&offset=' + offset
    );
  }

  getMasterPoSuppliers() {
    return this.dataService.get(this.BASEURL + this.MASTERPOSUPPLIERS);
  }

  mergeDemandSupplier(assignmentId) {
    return this.dataService.post(this.BASEURL + this.MERGEDEMANDSUPPLIERS, assignmentId);
  }

  editMasterPo(masterPoId) {
    return this.dataService.get(this.BASEURL + this.EDITMASTERPO + masterPoId);
  }

  createPo(demandData) {
    return this.dataService.post(this.BASEURL + this.CREATEPO, demandData);
  }

  getPOSuppliers() {
    return this.dataService.get(this.BASEURL + this.FETCHACTIVESUPPLIERSPO);
  }

  getPOSupplierswithWh(data) {
    return this.dataService.get(`${this.BASEURL}${this.FETCHACTIVESUPPLIERSPO}?warehouseId=${data}`);
  }

  getPOMasterData() {
    return this.dataService.get(this.BASEURL + this.POMASTERDATA);
  }

  cancel_assignment_po(id) {
    return this.dataService.get(this.BASEURL + this.CANCELASSIGNMENT_PO + '?id=' + id);
  }
  cancel_assignment_po_true(id, selectedReasonId) {
    return this.dataService.get(
      this.BASEURL + this.CANCELASSIGNMENT_PO + '?id=' + id + '&reasonId=' + selectedReasonId
    );
  }

  getPOAssignments(data, limit, offset) {
    return this.dataService.post(
      this.BASEURL + this.FTECHPOASSIGNMENTS + '?limit=' + limit + '&offset=' + offset,
      data
    );
  }

  assignSupplier(supplier) {
    return this.dataService.post(this.BASEURL + this.ASSIGNSUPPLIER, supplier);
  }

  assignSuppliers(suppliers) {
    return this.dataService.post(this.BASEURL + this.ASSIGNSUPPLIERS, suppliers);
  }

  reject_approve_Assignments(assignmentIds, approveFlag, reason?) {
    let body = {
      idList: assignmentIds.idList,
      remarks: reason.remarks
    }
    return this.dataService.post(
      this.BASEURL + (approveFlag ? this.APPROVEASSIGNMENTS : this.REJECTASSIGNMENTS),
      body
    );
  }

  reject_approve_Assignments_ViewAll(assignmentIds, approveFlag) {
    return this.dataService.post(
      this.BASEURL + (approveFlag ? this.APPROVEASSIGNMENTS : this.REJECTASSIGNMENTS),
      assignmentIds
    );
  }

  supplierRating(value) {
    return this.dataService.get(this.BASEURL + this.SUPPLIERRATING + value);
  }

  redFlagData(value) {
    return this.dataService.get(`${this.BASEURL}${this.SUPPLIERPERFORMANCE}?supplierId=${value}`);
  }

  getBrandDetailsDtata(reqdata) {
    return this.dataService.post(`${this.BASEURL}${this.BRAND_DETAILS}`, reqdata);
  }

  getHighValuePo(data, limit, offset) {
    return this.dataService.post(this.BASEURL + this.HIGHVALUEPO + '?limit=' + limit + '&offset=' + offset, data);
  }

  getTaxPercentage(obj) {
    return this.dataService.post(this.BASEEMSURL + this.getTaxPerc, obj);
  }
  pickUpHistory(value) {
    return this.dataService.get(this.BASEURL + this.GETPICKUPHISTORY + value);
  }
  getSaChallanItems(id, report) {
    return this.dataService.get(this.provisionalUrl + this.SACHALLAN + id + '?isReport=' + true);
  }
  getSourcerEmails(data) {
    return this.dataService.get(`${this.BASE_ACCOUNT_SERVICE_URL}${this.GET_SOURCER_EMAILS}?key=${data}&userType=INTERNAL`);
  }
  getCMStarDetails(data) {
    return this.dataService.get(`${this.BASEURL}${this.GET_CMSTAR_DETAILS}?id=${data.assignmentId}`);
  }

  getAutoFetchDemandData(data){
    return this.dataService.post(`${this.BASEURL}${this.GET_AUTOFETCH_DETAILS}`, data);
  }

  getUnReleasedOrder(data) {
    return this.dataService.post(`${this.BASEURL}${this.GET_UNRELEASED_ORDERS}?limit=${data.pageObj.pagelimit}&offset=${data.pageObj.pageOffset}`, data.data);
  }

  getUnReleasedItemsByOrder(data){
    return this.dataService.post(`${this.BASEURL}${this.GET_UNRELEASED_ITEMS}?limit=${data.pageObj.pageLimit}&offset=${data.pageObj.pageOffset}`, data.reqBody);
  }

  getLDClauseData(data) {
    return this.dataService.get(`${this.BASEURL}${this.GET_LDCLAUSE_LIST}?supplierId=${data.supplierId}`)
  }

  removeItemRowData(data) {
    return this.dataService.get(`${this.BASEURL}${this.REMOVE_ITEM_ROW}?spoItemId=${data.spoItemId}`)
  }

  releaseUnReleaseData(data) {
    return this.dataService.post(`${this.BASEURL}${this.RELEASE_UNRELEASE}`, data);
  }

  getUnReleasedItemsByOrderCompleteSetData(data): Observable<any> {
    return this.getUnReleasedItemsByOrder(data).pipe(
      map((responsedata: any) => {
        if (responsedata.success && responsedata.data) {
          const fgs = (responsedata.data.data && responsedata.data.data.unreleasedSpoItemsDtoList) ? responsedata.data.data.unreleasedSpoItemsDtoList.map(SourcingService.asFormGroup) : [];
          return { originaldata: responsedata, formdata: new FormArray(fgs) };
        }
        else if ( responsedata.success && !responsedata.data ) {
          return { originaldata: responsedata, formdata: [] };
        }
        else {
          let errorMsg = (responsedata && !responsedata.success && responsedata.responseMessage) ? responsedata.responseMessage : "Something Went Wrong";
          this.toastrService.error(errorMsg);
        }
      })
    );
  }

  static asFormGroup(itemlistdata: any): FormGroup {
    const fg = new FormGroup({
      itemRefs: new FormControl(itemlistdata.itemRefs),
      orderRefs: new FormControl(itemlistdata.orderRefs),
      productMsn: new FormControl(itemlistdata.productMsn),
      pickupDate: new FormControl(new Date(itemlistdata.pickupDate)),
      transferTax: new FormControl(itemlistdata.transferTax),
      transferPrice: new FormControl(itemlistdata.transferPrice),
      itemType: new FormControl(itemlistdata.itemType),
      purchaseOrderItemId: new FormControl(itemlistdata.purchaseOrderItemId)
    });
    return fg;
  }
  
}

<div *ngIf="userService.isLogin(); else elseBlock">
  <app-structure-top-nav
    *ngIf="userService.isLogin() && userService.isAuthenticated() && countryListArray != null && countryCode != null"
  ></app-structure-top-nav>
  <div class="wrapper">
    <app-structure-side-nav
      *ngIf="userService.isLogin() && userService.isAuthenticated() && countryListArray != null && countryCode != null"
    >
    </app-structure-side-nav>
    <div class="sidenav-content">
      <!--<div class="button_icon">
        <button mat-button mat-fab color="warn" style="margin-top: 850px; margin-left: 10px; z-index:35;position:fixed;" (click)="openDialog()"><mat-icon>help</mat-icon></button>
      </div> -->
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
<ng-template #elseBlock>
  <!-- <div class = "plain-pg-main-text" (click) = "navigateToLogin()" >Navigate To Login Page</div> -->
  <div>
    <router-outlet></router-outlet>
  </div>
</ng-template>

<ng-http-loader
  [backgroundColor]="'#d9232d'"
  [spinner]="spinkit.skThreeBounce"
  [filteredUrlPatterns]="['account/user/search-email']"
></ng-http-loader>

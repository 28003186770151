import { UserService } from './user.service';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { StructureSideNavService } from './structure/services/structure-side-nav.service';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { Spinkit } from 'ng-http-loader';
import * as $ from 'jquery';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { UtilService } from './util/services/util.service';
export let browserRefresh = false;
import { ToastrService } from 'ngx-toastr';
//import { MatDialog } from '@angular/material';
//import { DialogBoxComponent } from './dialog-box/dialog-box.component';
import { HttpClient } from '@angular/common/http';
import { data } from 'jquery';

@Component({
  selector: 'app-root',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  public spinkit = Spinkit;
  title: string = 'SC-UP';
  countryfilter: boolean = false;
  countryListArray: any;
  subscription: Subscription;

  countryCode: any;




  constructor(
    private router: Router,
    public userService: UserService,
    private utilService: UtilService,
    private toastr: ToastrService,
    public sideNavService: StructureSideNavService,
    //public dialog: MatDialog,
    private http: HttpClient
  ) {
  }
  /* openDialog(){
     
     this.dialog.open(DialogBoxComponent,{panelClass: 'custom-dialog-container'}) 
 } */


  ngOnInit() {

    localStorage.setItem("isMobileView", null);
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      localStorage.setItem("isMobileView", "true");
    } else {
      localStorage.setItem("isMobileView", "false");
    }
    
    setTimeout(() => {
      if ((this.router.url == "" || this.router.url == "/") && !this.userService.isLogin()) {
        this.router.navigate(['login']);
      }
    }, 1000);
    const token: string = localStorage.getItem('authToken');

    // window.addEventListener('storage', (event) => {
    //   if (event.key === 'logged_in') {
    //     location.reload()
    //   }
    // }, false)
    // this.initialNavigation();
    this.countryListArray = JSON.parse(localStorage.getItem('countryList'));
    this.countryCode = localStorage.getItem('selectedCountryCode')
    if ((this.countryListArray == null || this.countryListArray.length == 0)) {
      // this.toastr.warning('You dont have access for country');
      localStorage.removeItem('user');
      localStorage.removeItem('authToken');
      localStorage.removeItem('countryList');
      localStorage.removeItem('selectedCountryName');
      localStorage.removeItem('selectedCountryCode');
      // this.router.navigate(['login']);
    }
  }

  initialNavigation() {
    if (this.userService.isLogin) {
      this.router.events.pipe(filter((e) => e instanceof NavigationEnd)).subscribe((navEnd: NavigationEnd) => {
        let url = navEnd.urlAfterRedirects;
        if (url == '/sourcing') {
          this.router.navigate(['sourcing']);
        } else if (url == '/settings') {
          this.router.navigate(['settings']);
        } else if (url == '/salesops') {
          this.router.navigate(['salesops']);
        } else if (url.includes('warehousemanagement')) {
          this.router.navigate([url]);
        } else if (url.includes('procurement')) {
          this.router.navigate([url]);
        } 
        // else {
        //   this.router.navigate(['salesops']);
        // }
      });
    } else {
      this.router.navigate(['login']);
    }
  }

  navigateToLogin() {
    this.router.navigate(['/login']);
  }

  // signOut() {
  //   localStorage.clear();
  //   this.userService.signOut();
  //   this.router.navigate(['login'])
  //     .then(() => {
  //       window.localStorage.setItem('logged_in', 'false');
  //       window.location.reload();
  //     });
  // }

  navigateTo(page) {
    this.router.navigate([page]);
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
  ngAfterViewInit() { }
}

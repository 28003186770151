import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, count } from 'rxjs/operators';
import { Router } from '@angular/router';
import { UserService } from './user.service';
import { SourcingService } from './sourcing/services/sourcing.service';
import { environment } from '../environments/environment';
@Injectable()
export class AppInterceptor implements HttpInterceptor {
  environmentSource = environment.SOURCINGURL;
  environmentWMS = environment.WMSURL;
  environmentEMS = environment.BASEEMSURL;
  constructor(private router: Router, public userService: UserService, public sourcingService: SourcingService) {}
  cloneReq: any;
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    
    const authToken: string = localStorage.getItem('authToken');
    const token: string = authToken ? `Bearer ${localStorage.getItem('authToken')}` : '';
    const application: string = 'SCM';

    const countryCode = this.userService.getSelectedCountryCode();
    if (request.url.includes(this.environmentSource)) {
      let headers = request.headers
        .set('countryId', countryCode)
        .set('Authorization', token)
        .set('token', token);
      request = request.clone({ headers });
    } else if (request.url.includes(this.environmentWMS)) {
      let headers = request.headers.set('countryId', countryCode).set('Authorization', token);
      request = request.clone({ headers });
    } else if (request.url.includes(this.sourcingService.BASE_ACCOUNT_SERVICE_URL)) {
      let headers = request.headers.set('Authorization', token).set('token', token).set('source', application);
      request = request.clone({ headers });
    } else if (request.url.includes(this.environmentEMS)) {
      let headers = request.headers.set('Authorization', token);
      request = request.clone({ headers });
    }  else if (!request.url.includes('.jpg' && 'amazonaws')&& (request.url !=="https://moglilabs.freshservice.com/api/v2/tickets" && request.url !=="https://moglilabs.freshservice.com/api/v2/ticket_form_fields")) {
      if (token) {
        let headers = request.headers.set('countryId', countryCode).set('Authorization', token);
        request = request.clone({ headers });
        // request = request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + token) });
      } else {
        this.router.navigate(['login']);
      }
    }
    else if(request.url =="https://moglilabs.freshservice.com/api/v2/tickets" || request.url=="https://moglilabs.freshservice.com/api/v2/ticket_form_fields"){
      
      let headers = request.headers
        .set('countryId', countryCode)
         .set("Authorization", "Basic " + btoa("POnL4iLubnZl1NpPadVK:X"))
       request = request.clone({ headers });
    }
   /* if (reqURl.includes('/api/v2/tickets'))
    {let headers=request.headers
      .set('countryId', countryCode)
      .set("Authorization", "Basic " + btoa("username:password"))
    }*/
    return next.handle(request).pipe(catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse) {
    if (error['status'] == 401) {
      localStorage.removeItem('user');
      localStorage.removeItem('authToken');
      localStorage.removeItem('countryList');
      localStorage.removeItem('selectedCountryName');
      localStorage.removeItem('selectedCountryCode');
    }
    // if(error['status']== 401){
    //   this.userService.signOut();
    // }
    return throwError(error);
  }
}

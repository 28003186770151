import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatTableDataSource } from '@angular/material';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-st-in-transit-invoice-popup',
  templateUrl: './st-in-transit-invoice-popup.component.html',
  styleUrls: ['./st-in-transit-invoice-popup.component.scss']
})
export class StInTransitInvoicePopupComponent implements OnInit {
  displayedColumns: string[] = ['invoiceno', 'invoiceurl', 'packedQty', 'awb', 'status'];
  dataSource = new MatTableDataSource<any>();

  constructor(@Inject(MAT_DIALOG_DATA) public receivedData: any,
    private toastr: ToastrService) { }

  ngOnInit() {
    if (this.receivedData.invoice) {
      this.dataSource.data = this.receivedData.invoiceDetail;
    } else {
      this.dataSource.data = this.receivedData.itemDetail;
    }
  }
  onOpenInvoiceUrl(url): void {
    window.open("https://" + url, "_blank")
  }

}

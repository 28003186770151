<div class="stn_details">
  <div class="content">
    <div class="top_header">
      <div class="left">
        <span>
          STN Number :
          <label>{{this.getSTNID}}</label>
        </span>
      </div>
      <button mat-button mat-dialog-close>Close</button>
    </div>

    <div class="middlesection">

      <div class="stn-filter p-form-wrapper">
        <div class="p-dropdown">
          <mat-form-field appearance="outline">
            <mat-label>From Warehouse ID </mat-label>
            <mat-select disabled [(ngModel)]="warehouseFrom" [(value)]="warehouseFrom">
              <mat-option disabled color="warn" *ngFor="let warehouse of warehouseList" [value]="warehouse.id">
                {{ warehouse.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="p-dropdown">
          <mat-form-field appearance="outline">
            <mat-label>STN Type </mat-label>
            <mat-select disabled [(ngModel)]="STNType">
              <mat-option disabled value="CUSTOMER_ORDER">CUSTOMER_ORDER
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <!-- <div class="p-dropdown">
              <mat-form-field appearance="outline">
                <mat-label>Item Ref </mat-label>
                <input matInput [(ngModel)]="itemRef">
              </mat-form-field>
            </div> -->
        <div class="p-dropdown">
          <mat-form-field appearance="outline">
            <mat-label>To Warehouse ID</mat-label>
            <mat-select disabled [(ngModel)]="warehouseTo">
              <mat-option disabled color="warn" *ngFor="let warehouse of warehouseList" [value]="warehouse.id">
                {{ warehouse.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="p-dropdown">
          <mat-form-field appearance="outline">
            <mat-label>Item Ref</mat-label>
            <input disabled matInput [(ngModel)]="itemRef">
          </mat-form-field>
        </div>
        <div class="p-dropdown">
          <mat-form-field appearance="outline">
            <mat-label> Pick Up Date</mat-label>
            <input matInput disabled [(ngModel)]="pickupDate">
          </mat-form-field>
        </div>
      </div>

      <div class="p-form-wrapper">
        <div class="p-dropdown">
          <mat-form-field appearance="outline">
            <mat-label>Freight Charges </mat-label>
            <input readonly matInput [(ngModel)]="freightCharge" disabled>
          </mat-form-field>
        </div>
        <div class="p-dropdown">
          <mat-form-field appearance="outline">
            <mat-label>Misc Charges </mat-label>
            <input matInput readonly [(ngModel)]="miscCharges" disabled>
          </mat-form-field>
        </div>
        <div class="p-dropdown">
          <mat-form-field appearance="outline">
            <mat-label>Remarks </mat-label>
            <input matInput readonly [(ngModel)]="remarks" disabled>
          </mat-form-field>
        </div>

      </div>
      
      
      <div class="tableStructure">
        <table mat-table [dataSource]="dataSource">
          <ng-container matColumnDef="srNo">
            <th mat-header-cell *matHeaderCellDef> Sr. No. </th>
            <td mat-cell *matCellDef="let element; let i= index; "> {{i+1}} </td>
          </ng-container>

          <ng-container matColumnDef="msn">
            <th mat-header-cell *matHeaderCellDef> MSN </th>
            <td mat-cell *matCellDef="let element"> {{element.productMsn}} </td>
          </ng-container>

          <ng-container matColumnDef="msnDescription">
            <th mat-header-cell *matHeaderCellDef> MSN Description </th>
            <td mat-cell *matCellDef="let element"> {{element.productName}} </td>
          </ng-container>
          <ng-container matColumnDef="bin">
            <th mat-header-cell *matHeaderCellDef> Bin </th>
            <td mat-cell *matCellDef="let element"> {{element.binName}} </td>
          </ng-container>
          <ng-container matColumnDef="zone">
            <th mat-header-cell *matHeaderCellDef>Zone </th>
            <td mat-cell *matCellDef="let element"> {{element.zoneName}} </td>
          </ng-container>

          <ng-container matColumnDef="qty">
            <th mat-header-cell *matHeaderCellDef> Qty </th>
            <td mat-cell *matCellDef="let element"> {{element.quantity}} </td>
          </ng-container>
          <ng-container matColumnDef="price">
            <th mat-header-cell *matHeaderCellDef> Price </th>
            <td mat-cell *matCellDef="let element"> {{element.purchasePrice}} </td>
          </ng-container>
          <ng-container matColumnDef="tax">
            <th mat-header-cell *matHeaderCellDef> Tax </th>
            <td mat-cell *matCellDef="let element"> {{element.taxPercentage}}%</td>
          </ng-container>
          <ng-container matColumnDef="spoId">
            <th mat-header-cell *matHeaderCellDef> SPO ID </th>
            <td mat-cell *matCellDef="let element"> {{element.supplierPoId}} </td>
          </ng-container>
          <ng-container matColumnDef="spoItem">
            <th mat-header-cell *matHeaderCellDef> SPO Item ID </th>
            <td mat-cell *matCellDef="let element"> {{element.supplierPoItemId}} </td>
          </ng-container>
          <ng-container matColumnDef="itemRef">
            <th mat-header-cell *matHeaderCellDef> Item Ref. </th>
            <td mat-cell *matCellDef="let element"></td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <div class="dummyData" *ngIf="dataSource.data.length ==0">Start Filtering to get the Data</div>
      </div>
      
    </div>
  </div>
</div>
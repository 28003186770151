export const environment = {
  FE_URL: 'https://scm-qa1.moglilabs.com',
  BASEEMSURL: 'https://ems-qa1.moglilabs.com/api/',
  EMSWEBURL:'https://ems-qa1.moglilabs.com',
  EMSURL: 'https://ems-qa1.moglilabs.com/api/UnifiedPlatform/userAuthentication.json',
  WMSURL: 'https://wms-qa1.moglilabs.com/api/',
  CATALOGURL : "https://catalogqa1.moglix.com/",
  WMSBULKINVOICE: 'https://eis-qa1.moglilabs.com/api/',
  // SOPURL: 'https://salesopsqa.moglilabs.com/api/',
  // PROVISIONALURL: 'https://salesopsqa.moglilabs.com/api/',
  // SOURCINGURL: 'https://purchase.moglilabs.com/',
  SOPURL: 'https://salesopsapi-qa1.moglilabs.com/api/',
  PROVISIONALURL: 'https://salesopsapi-qa1.moglilabs.com/api/',
  SOURCINGURL: 'https://purchase-qa1.moglilabs.com/',
  CONGFIGURL: 'https://scconfig-qa1.moglilabs.com/' ,//'https://scconfig.moglilabs.com/',
  LSMURL:'https://lms-qa1.moglilabs.com/api/',
  WMSPACKURL :'https://wmspack-qa1.moglilabs.com/api/',
  //  CLIENTID: '177917627105-vr5kg4gdrqkgr7h4q90hgpj92dc21t6g.apps.googleusercontent.com',
  CLIENTID: '851176149543-hnse4hg53io8dm1jijnj6f0tj84cf3lm.apps.googleusercontent.com',
  ACCOUNT_SERVICE_URL: 'https://mce-account-service-qa1.moglilabs.com/',//'https://dev.account-service.moglilabs.com/',
  MICROSOFT_CLIENT_ID:"7ef76858-5a61-4c38-9937-e94e999bac4a",
  MICROSOFT_AUTHORITY_URI:'https://login.microsoftonline.com/421e9584-87ff-424f-95b8-1bf46b70db99/',
  MICROSOFT_REDIRECT_URI: '/',
  production: false,
};

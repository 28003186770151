import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { MatTableDataSource } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';
import { DataService } from 'src/app/util/services/data.service';
// import { ReceivedDetailsComponent } from '../received-material/received-details/received-details.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-in-progress-detail',
  templateUrl: './in-progress-detail.component.html',
  styleUrls: ['./in-progress-detail.component.scss']
})
export class InProgressDetailComponent implements OnInit {

  getSTNID: any;
  readonly WMSURL = environment.WMSURL;
  dataSource = new MatTableDataSource<any>();
  displayedColumns = ['srNo', 'msn', 'msnDescription', 'bin', 'zone', 'qty', 'price', 'tax', 'spoId', 'spoItem'];
  warehouseList = [];
  warehouseFrom: number;
  STNType: any;
  itemRef: any;
  warehouseTo: number;
  freightCharge: any;
  miscCharges: any;
  remarks: any;
  pickupDate: any;
  constructor(private dialogRef: MatDialogRef<InProgressDetailComponent>,
    private toastr: ToastrService,
    private dataService: DataService,
    @Inject(MAT_DIALOG_DATA) public inProgressSTNData: any) {
    this.getWarehouseList();
    this.getSTNID = this.inProgressSTNData.stockTransferNoteId;

  }

  getWarehouseList() {
    this.dataService.get(this.WMSURL + 'warehouse/search').subscribe((data) => {
      this.warehouseList = data['warehouses'];
    });
  }
  ngOnInit() {
    this.dataService.get(this.WMSURL + 'stn/' + this.getSTNID).subscribe((list: any) => {
      this.dataSource.data = list['stockTransferItem'];
      this.warehouseFrom = list['warehouseSource'];
      this.warehouseTo = list['warehouseTarget'];
      this.STNType = list['stockTransferNoteType'];
      this.freightCharge = list['freightCharge'];
      this.miscCharges = list['miscCharges'];
      this.remarks = list['remarks'];
      this.itemRef = list['itemRef'];
      this.pickupDate = list['pickupDate']

    })
  }
}

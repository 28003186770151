import { Component, OnInit, Renderer2, ElementRef, ViewChild } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-structure-side-nav',
  templateUrl: './structure-side-nav.component.html',
  styleUrls: ['./structure-side-nav.component.scss'],
})
export class StructureSideNavComponent implements OnInit {
  icon: boolean = false;
  selectedId: any = 1;
  @ViewChild('sidebar') sidebar!: ElementRef;
  readonly EMSWEBURL = environment.EMSWEBURL;
  env = environment.FE_URL

  constructor(private router: Router, private renderer: Renderer2) {
    document.addEventListener('click', this.closeContextMenuOnClickOutside.bind(this));
  }

  ngOnInit() {
    
    this.getSelectedId();
    if ($('#sidebar').hasClass('hidden2')) {
      $(this)
        .parent('.side-nav')
        .addClass('active');
    }
    let submenuwidth = 0;

    // $('.navsubmenuwrap').each(function() {
    //   submenuwidth += $(this).outerWidth(true);
    // });
    // $('.navsubmenu').css('width', submenuwidth);
  }

  navigateTo(id) {
    this.selectedId = id;
    // window.location.href = page;
    // this.router.navigate([page])
    // .then(() => {
    //   window.location.reload();
    // });
  }

  getSelectedId() {
    this.router.events.pipe(filter((e) => e instanceof NavigationEnd)).subscribe((navEnd: NavigationEnd) => {
      let url = navEnd.urlAfterRedirects;
      if (url == '/salesops') {
        this.selectedId = 1;
      } else if (url.split('/')[1] == 'sourcing') {
        this.selectedId = 2;
      } else if (url.split('/')[1] == 'warehousemanagement') {
        this.selectedId = 4;
      } else if (url.split('/')[1] == 'procurement') {
        this.selectedId = 3;
      } else if (url == '/settings') {
        this.selectedId = 5;
      } else if (url.split('/')[1] == 'invoice') {
        this.selectedId = 6;
      } else {
        this.selectedId = 1;
        this.navigateTo(this.selectedId);
        this.router.navigateByUrl('/salesops');
      }
    });
  }

  navigateRoute(route) {
    this.router.navigate([route]);
    this.getActivatedRoute(route);
  }

  getActivatedRoute(route) {
    let isActive = false;
    if (this.router.url == route) {
      isActive = true;
    }
    return isActive;
  }

  navigateToUrl(page) {
    window.location.href = page;
  }

  navigateToRoute(routeendpoint) {
    this.router.navigate([routeendpoint]);
  }

  changeLogo() {
    this.icon = !this.icon;
  }
  menuClick() {
     this.sidebar.nativeElement.classList.toggle('close');
  }
  AddMenuClick() {
     this.renderer.removeClass(this.sidebar.nativeElement, 'close');
  }
  removeMenuClick() {
     this.renderer.addClass(this.sidebar.nativeElement, 'close');
  }

  toggleDropdpwnMenu(event: MouseEvent) {
    const target = event.currentTarget as HTMLElement;
    const liElement: any = target.parentElement;
    liElement.classList.toggle('showMenu');
  }

  showContextMenu = false;
  contextMenuPosition = { x: '0px', y: '0px' };
  linkUrl; // The URL you want to open

  onRightClick(event: MouseEvent, routerlink) {
    event.preventDefault(); // Prevent the default context menu
    this.contextMenuPosition.x = event.clientX + 'px';
    this.contextMenuPosition.y = event.clientY + 'px';
    this.linkUrl = this.env+routerlink;
    this.showContextMenu = true;
  }

  openInNewTab() {
    window.open(this.linkUrl, '_blank');
    this.showContextMenu = false;
  }

  // openInNewWindow() {
  //   window.open(this.linkUrl, '_blank', 'noopener,noreferrer');
  //   this.showContextMenu = false;
  // }

  openInNewWindow() {
    // const url = this.router.serializeUrl(this.router.createUrlTree([this.linkUrl]));
    // console.log("url", url);
    // const url = this.router.serializeUrl(this.router.createUrlTree(['/your-route']));
    const windowFeatures = 
    'width=1050, height=1000, toolbar=yes, location=no, status=no, menubar=no, scrollbars=yes, resizable=yes'
    
    window.open(this.linkUrl, '_blank', windowFeatures);
    // // Set unique name for each new window to prevent reusing the same window
    // const uniqueWindowName = `window-${new Date().getTime()}`;
    // const windowFeatures = 'width=800,height=600,scrollbars=yes,resizable=yes';

    // window.open(url, uniqueWindowName, windowFeatures); // Open in new window with custom features
    // this.showContextMenu = false; // Close the context menu
    // const url = this.router.serializeUrl(this.router.createUrlTree([this.linkUrl]));

    // // Set unique name for each new window to prevent reusing the same window
    // const uniqueWindowName = `window-${new Date().getTime()}`;
    // const windowFeatures = 'width=800,height=600,scrollbars=yes,resizable=yes';

    // window.open(url, uniqueWindowName, windowFeatures); // Open in new window with custom features
    // this.showContextMenu = false; // Close the context menu
    // const width = window.screen.availWidth;
    // const height = window.screen.availHeight;
    // const left = 0;
    // const top = 0;
    // const windowFeatures = `width=${window.screen.availWidth},height=${window.screen.availHeight},scrollbars=yes,resizable=yes`;
    // window.open(this.linkUrl, '_blank', windowFeatures);
    // this.showContextMenu = false;
  }

  inspectElement(event: MouseEvent) {
    console.log('Element inspected:', event.target);
    console.dir(event.target);  // Shows full details in the console
    this.showContextMenu = false;
  }

  openDeveloperConsole() {
    alert('Press F12 or right-click the element and choose "Inspect" to inspect elements.');
    this.showContextMenu = false;
  }
  
  copyLinkAddress() {
    const url = this.linkUrl;
  
    // Using 'as any' to bypass TypeScript error
    (navigator as any).clipboard.writeText(url).then(() => {
      // alert('Link copied to clipboard: ' + url);
    }).catch(err => {
      // console.error('Failed to copy: ', err);
    });
    
    this.showContextMenu = false;
  }

  saveLinkAddress() {
    const url = window.location.origin + this.router.serializeUrl(this.router.createUrlTree([this.linkUrl]));
    
    // Save to localStorage (or sessionStorage if preferred)
    localStorage.setItem('savedLink', url);

    // Optional: Provide feedback to user
    alert('Link saved: ' + url);
    this.showContextMenu = false;
  }

  closeContextMenuOnClickOutside(event: MouseEvent) {
    if (this.showContextMenu) {
      this.showContextMenu = false; // Close the context menu
    }
  }

  ngOnDestroy() {
    document.removeEventListener('click', this.closeContextMenuOnClickOutside.bind(this));
  }

}

<div class="example-container">
	<div class="modal-header">
		<h4>Submit A Ticket</h4>
		<button mat-button class="close-btn" (click)="onNoClick()"><i class="ri-close-line"></i></button>
	</div>
	
	<div class="form-container overflow-scroll-1 submit-ticket-form">
		
		<!-- <span style="float: right;color:#464646;cursor: pointer;margin-top:-45px;margin-right:20px;"
			(click)="onNoClick()"><i style="font-size: 28px;background-color:'grey'"
				class="material-icons">clear</i></span> -->
		<mat-form-field appearance="fill">
			<mat-label>Support Team<span class="required">*</span></mat-label>
			<!-- <mat-select [(ngModel)]="support_team">
				<mat-option class="p-matselectdrop" *ngFor="let support_team of supportTeamData"
					[value]="support_team.value">{{support_team.value}}</mat-option>
			</mat-select> -->
			<input matInput [(ngModel)]="support_team" readonly>
		</mat-form-field>
		<mat-form-field appearance="fill">
			<mat-label>Search a Requestor<span class="required">*</span></mat-label>
			<input matInput placeholder="Search a Requestor" [(ngModel)]="email" readonly>
		</mat-form-field>

		<mat-form-field class="example-chip-list" appearance="fill">
			<mat-label>CC emails</mat-label>
			<mat-chip-list #chipList aria-label="Email selection">
				<mat-chip *ngFor="let x of mail" (removed)="remove(x)">
					{{x}}
					<mat-icon matChipRemove>cancel</mat-icon>
				</mat-chip>
				<input placeholder="Email..." #emailInput [formControl]="cc_emails" [matAutocomplete]="auto"
					[matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
					(keyup)=getccMail() (matChipInputTokenEnd)="add($event)">
			</mat-chip-list>
			<mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
				<mat-option *ngFor="let xa of ccEmailArr " [value]="xa.email">
					{{xa.email}}
				</mat-option>
			</mat-autocomplete>
		</mat-form-field>

		<mat-form-field  appearance="fill">
			<mat-label>Requestor Team</mat-label>
			<mat-select [(ngModel)]="requester_team">
				<mat-option class="p-matselectdrop" *ngFor="let requester_team of requesterTeamData"
					[value]="requester_team.value">{{requester_team.value}}</mat-option>
			</mat-select>
		</mat-form-field>
		<mat-form-field appearance="fill" *ngIf="this.requester_team=='Enterprise' || this.requester_team=='Warehouse'">
			<mat-label>Region</mat-label>
			<mat-select [(ngModel)]="region">
				<mat-option class="p-matselectdrop" *ngFor="let regionlist of regionData" [value]="regionlist.value">
					{{regionlist.value}} </mat-option>
			</mat-select>
		</mat-form-field>
		<mat-form-field appearance="fill">
			<mat-label>Subject<span class="required">*</span></mat-label>
			<input matInput placeholder="Subject" [(ngModel)]="subject">
		</mat-form-field>
		<mat-form-field appearance="fill">
			<mat-label>Portal URl</mat-label>
			<input matInput placeholder="Portal URl" [(ngModel)]="fetchUrl">
		</mat-form-field>
		<mat-form-field  appearance="fill">
			<mat-label> Issue</mat-label>
			<mat-select [(ngModel)]="issue">
				<mat-option class="p-matselectdrop" *ngFor="let issue1 of issueType" [value]="issue1.value">
					{{issue1.value}}</mat-option>
			</mat-select>
		</mat-form-field>
		<mat-form-field appearance="fill" *ngIf="this.issue=='Access Request'">
			<mat-label>Subtype1</mat-label>
			<mat-select [(ngModel)]="accesssub1">
				<mat-option class="p-matselectdrop" *ngFor="let sub1 of accessIssue" [value]="sub1.value">{{sub1.value}}
				</mat-option>
			</mat-select>
		</mat-form-field>
		<mat-form-field appearance="fill" *ngIf="this.accesssub1=='Analytics'">
			<mat-label>Business Unit</mat-label>
			<mat-select [(ngModel)]="business">
				<mat-option class="p-matselectdrop" *ngFor="let business1 of businessUnit" [value]="business1.value">
					{{business1.value}} </mat-option>
			</mat-select>
		</mat-form-field>
		<mat-form-field appearance="fill" *ngIf="this.accesssub1=='Analytics'">
			<mat-label>Function </mat-label>
			<mat-select [(ngModel)]="function">
				<mat-option class="p-matselectdrop" *ngFor="let function1 of functionData" [value]="function1.value">
					{{function1.value}} </mat-option>
			</mat-select>
		</mat-form-field>
		<mat-form-field *ngIf="this.accesssub1=='Analytics'">
			<mat-label>Warehouse Name</mat-label>
			<input matInput placeholder="Warehouse Name" [(ngModel)]="warehouse_name">
		</mat-form-field>
		<mat-form-field *ngIf="this.accesssub1=='Buyers'">
			<mat-label>Plant ID</mat-label>
			<input type="number" matInput placeholder="Plant id" [(ngModel)]="plantId">
		</mat-form-field>
		<mat-form-field appearance="fill" *ngIf="this.accesssub1=='EMS'">
			<mat-label>Role </mat-label>
			<mat-select [(ngModel)]="accessSub2" multiple>
				<mat-option class="p-matselectdrop" *ngFor="let role of accessRoleData" [value]="role.value">
					{{role.value}} </mat-option>
			</mat-select>
		</mat-form-field>
		<mat-form-field  *ngIf="this.accesssub1=='EOC'">
			<mat-label>Team Name</mat-label>
			<input matInput placeholder="Team Name" [(ngModel)]="team">
		</mat-form-field>
		<mat-form-field appearance="fill" *ngIf="this.accesssub1=='OMS'">
			<mat-label>OMS Role </mat-label>
			<mat-select [(ngModel)]="accessSub2">
				<mat-option class="p-matselectdrop" *ngFor="let oms of omsAccessRole" [value]="oms.value">{{oms.value}}
				</mat-option>
			</mat-select>
		</mat-form-field>
		<mat-form-field *ngIf="this.accesssub1=='Runner / SupplyChain'">
			<mat-label>Mobile Number</mat-label>
			<input matInput placeholder="Team Name" [(ngModel)]="mobile">
		</mat-form-field>
		<mat-form-field *ngIf="this.accesssub1=='Runner / SupplyChain'">
			<mat-label>IMEI 1</mat-label>
			<input matInput placeholder="IMEI 1" [(ngModel)]="imei">
		</mat-form-field>
		<mat-form-field *ngIf="this.accesssub1=='Runner / SupplyChain'">
			<mat-label>IMEI 2</mat-label>
			<input matInput placeholder="IMEI 2" [(ngModel)]="imei2">
		</mat-form-field>
		<mat-form-field *ngIf="this.accesssub1=='Runner / SupplyChain'">
			<mat-label>Warehouse Name</mat-label>
			<input matInput placeholder="Warehouse Name" [(ngModel)]="warehouse_name">
		</mat-form-field>
		<mat-form-field *ngIf="this.accesssub1=='SCM / Salesops'">
			<mat-label>Team Name</mat-label>
			<input matInput placeholder="Team Name" [(ngModel)]="team">
		</mat-form-field>
		<mat-form-field *ngIf="this.accesssub1=='Supplier Central'">
			<mat-label>Mobile Number</mat-label>
			<input matInput placeholder="Mobile Number" [(ngModel)]="mobile">
		</mat-form-field>
		<mat-form-field appearance="fill" *ngIf="this.issue=='Bulk Invoicing Issue'">
			<mat-label>Subtype1</mat-label>
			<mat-select [(ngModel)]="bulksub1">
				<mat-option class="p-matselectdrop" *ngFor="let bulk of bulkinvoice" [value]="bulk.value">{{bulk.value}}
				</mat-option>
			</mat-select>
		</mat-form-field>
		<mat-form-field appearance="fill" *ngIf="this.bulksub1=='EMS'">
			<mat-label>Subtype2</mat-label>
			<mat-select [(ngModel)]="bulksub2">
				<mat-option class="p-matselectdrop" *ngFor="let bulk1 of bulkinvoiceChoices" [value]="bulk1.value">
					{{bulk1.value}} </mat-option>
			</mat-select>
		</mat-form-field>
		<mat-form-field appearance="fill" *ngIf="this.bulksub1=='EOC'">
			<mat-label>Subtype2</mat-label>
			<mat-select [(ngModel)]="bulksub2">
				<mat-option class="p-matselectdrop" *ngFor="let bulk2 of bulkinvoiceChoices1" [value]="bulk2.value">
					{{bulk2.value}} </mat-option>
			</mat-select>
		</mat-form-field>
		<mat-form-field appearance="fill" *ngIf="this.bulksub1=='SCM'">
			<mat-label>Subtype2</mat-label>
			<mat-select [(ngModel)]="bulksub2">
				<mat-option class="p-matselectdrop" *ngFor="let bulk3 of bulkinvoiceChoices2" [value]="bulk3.id">
					{{bulk3.value}} </mat-option>
			</mat-select>
		</mat-form-field>
		<mat-form-field appearance="fill" *ngIf="this.issue=='Creation Request'">
			<mat-label>creation </mat-label>
			<mat-select [(ngModel)]="creation">
				<mat-option class="p-matselectdrop" *ngFor="let creationReq of creationData"
					[value]="creationReq.value">{{creationReq.value}} </mat-option>
			</mat-select>
		</mat-form-field>
		<mat-form-field appearance="fill" *ngIf="this.issue=='Tech Issue'">
			<mat-label>Subtype1</mat-label>
			<mat-select [(ngModel)]="techsub1">
				<mat-option class="p-matselectdrop" *ngFor="let tech of techissue" [value]="tech.value">{{tech.value}}
				</mat-option>
			</mat-select>
		</mat-form-field>
		<mat-form-field appearance="fill" *ngIf="this.techsub1=='Buyers'">
			<mat-label>Subtype2</mat-label>
			<mat-select [(ngModel)]="techsub2">
				<mat-option class="p-matselectdrop" *ngFor="let tech1 of buyertechissue" [value]="tech1.value">
					{{tech1.value}} </mat-option>
			</mat-select>
		</mat-form-field>
		<mat-form-field appearance="fill" *ngIf="this.techsub1=='EMS'">
			<mat-label>Subtype2</mat-label>
			<mat-select [(ngModel)]="techsub2">
				<mat-option class="p-matselectdrop" *ngFor="let tech2 of emstechissue" [value]="tech2.value">
					{{tech2.value}} </mat-option>
			</mat-select>
		</mat-form-field>
		<mat-form-field appearance="fill" *ngIf="this.techsub1=='EOC'">
			<mat-label>Subtype2</mat-label>
			<mat-select [(ngModel)]="techsub2">
				<mat-option class="p-matselectdrop" *ngFor="let tech3 of eoctechissue" [value]="tech3.value">
					{{tech3.value}} </mat-option>
			</mat-select>
		</mat-form-field>
		<mat-form-field appearance="fill" *ngIf="this.techsub1=='WMS'">
			<mat-label>Subtype2</mat-label>
			<mat-select [(ngModel)]="techsub2">
				<mat-option class="p-matselectdrop" *ngFor="let tech4 of wmstechissue" [value]="tech4.value">
					{{tech4.value}} </mat-option>
			</mat-select>
		</mat-form-field>
		<mat-form-field appearance="fill" *ngIf="this.techsub1=='OMS'">
			<mat-label>Subtype2</mat-label>
			<mat-select [(ngModel)]="techsub2">
				<mat-option class="p-matselectdrop" *ngFor="let tech5 of omstechissue" [value]="tech5.value">
					{{tech5.value}} </mat-option>
			</mat-select>
		</mat-form-field>
		<mat-form-field appearance="fill" *ngIf="this.techsub1=='SCM / Salesops'">
			<mat-label>Subtype2</mat-label>
			<mat-select [(ngModel)]="techsub2">
				<mat-option class="p-matselectdrop" *ngFor="let tech6 of scmtechissue" [value]="tech6.value">
					{{tech6.value}} </mat-option>
			</mat-select>
		</mat-form-field>
		<mat-form-field appearance="fill" *ngIf="this.techsub1=='Runner / SupplyChain'">
			<mat-label>Subtype2</mat-label>
			<mat-select [(ngModel)]="techsub2">
				<mat-option class="p-matselectdrop" *ngFor="let tech7 of runnertechissue" [value]="tech7.value">
					{{tech7.value}} </mat-option>
			</mat-select>
		</mat-form-field>
		<mat-form-field appearance="fill" *ngIf="this.techsub1=='EMS (Finance)'">
			<mat-label>Subtype2</mat-label>
			<mat-select [(ngModel)]="techsub2">
				<mat-option class="p-matselectdrop" *ngFor="let tech8 of financeissue" [value]="tech8.value">
					{{tech8.value}} </mat-option>
			</mat-select>
		</mat-form-field>

		<mat-form-field hintLabel="Max 100 characters" appearance="fill">
			<mat-label>Description<span class="required">*</span></mat-label>
			<input matInput #input maxlength="100" placeholder="" [(ngModel)]="description">
			<mat-hint align="end">{{input.value?.length || 0}}/100</mat-hint>
		</mat-form-field>
		<div>
			<input type="file" class="file-input" (change)="onFileSelected($event)" #fileUpload name="file"
				accept=".pdf,.jpg,.jpeg,.png" formControlName="file_upload" ng-model="file_upload">
			<div class="file-upload">

				{{fileName || "No file uploaded yet."}}

				<button mat-mini-fab color="warn" class="upload-btn" (click)="fileUpload.click()">
					<mat-icon>attach_file</mat-icon>
				</button>
			</div>
		</div>
	</div>
	<div class="modal-footer">
		<button mat-button class="sub-btn" color="warn" (click)="onSubmit()">Submit</button>
		<!-- <button mat-button class="sub-btn"  (click)="onSubmit()" style="float:right;margin-right: 10px;background-color:rgb(160, 153, 153)" (click)="onNoClick()">Close</button> -->
	</div>
	
</div>
<i (click)="menuClick()" class="ri-menu-fill hamburger-sidebar"></i>
<div #sidebar class="atom-sidebar close" (mouseover)="AddMenuClick()" (mouseout)="removeMenuClick()">
  <ul class="nav-links scrollernav">
    <li [ngClass]="{ active: selectedId == 1 }" (click)="navigateTo(1)" routerLinkActive="active" routerLink="/salesops" (contextmenu)="onRightClick($event, '/salesops')" >
      <a class = "cursor-pointer">
        <i class="ri-article-fill"></i>
        <span class="link_name">Sales/ Customer PO</span>
      </a>
    </li>

    <!-- <li [ngClass]="{ active: selectedId == 2 }" class="nav-item" (click)="navigateTo(2)" routerLinkActive="active" routerLink="/sourcing" (contextmenu)="onRightClick($event, '/sourcing')" >
      <a class = "cursor-pointer" >
        <i class="ri-scan-2-fill"></i>
        <span class="link_name">Sourcing</span>
      </a>
      <ul class="sub-menu blank">
        <li><a class="link_name">Category</a></li>
      </ul>
    </li>  -->
    <li [ngClass]="{ active: selectedId == 2 }">
      <div class="iocn-link cursor-pointer" (click)="toggleDropdpwnMenu($event)">
        <a>
          <i class="ri-scan-2-fill"></i>
          <span class="link_name">Sourcing</span>
        </a>
        <i class="ri-arrow-down-s-line arrow"></i>
      </div>
      <ul class="sub-menu">
        <li (click)=" navigateTo(2); navigateRoute('/sourcing/0')"
          [ngClass]="{ 'tab-active': getActivatedRoute('/sourcing/0') }" routerLinkActive="active" (contextmenu)="onRightClick($event, '/sourcing/0')">
          <a class="child-link" style="cursor: pointer !important">Assign Supplier</a>
        </li>
        <li (click)=" navigateTo(2); navigateRoute('/sourcing/1')"
          [ngClass]="{ 'tab-active': getActivatedRoute('/sourcing/1') }" routerLinkActive="active" (contextmenu)="onRightClick($event, '/sourcing/1')">
          <a class="child-link" style="cursor: pointer !important">Approve Assignment</a>
        </li>
        <li (click)=" navigateTo(2); navigateRoute('/sourcing/2')"
          [ngClass]="{ 'tab-active': getActivatedRoute('/sourcing/2') }" routerLinkActive="active" (contextmenu)="onRightClick($event, '/sourcing/2')">
          <a class="child-link" style="cursor: pointer !important">PO Amendment</a>
        </li>
        <li (click)=" navigateTo(2); navigateRoute('/sourcing/3')"
          [ngClass]="{ 'tab-active': getActivatedRoute('/sourcing/3') }" routerLinkActive="active" (contextmenu)="onRightClick($event, '/sourcing/3')">
          <a class="child-link" style="cursor: pointer !important">Create Supplier Purchase Order</a>
        </li>
        <li (click)=" navigateTo(2); navigateRoute('/sourcing/4')"
          [ngClass]="{ 'tab-active': getActivatedRoute('/sourcing/4') }" routerLinkActive="active" (contextmenu)="onRightClick($event, '/sourcing/4')">
          <a class="child-link" style="cursor: pointer !important">Unreleased SPO</a>
        </li>
        <li (click)=" navigateTo(2); navigateRoute('/sourcing/5')"
          [ngClass]="{ 'tab-active': getActivatedRoute('/sourcing/5') }" routerLinkActive="active" (contextmenu)="onRightClick($event, '/sourcing/5')">
          <a class="child-link" style="cursor: pointer !important">Provisional PO</a>
        </li>
        <li (click)=" navigateTo(2); navigateRoute('/sourcing/6')"
          [ngClass]="{ 'tab-active': getActivatedRoute('/sourcing/6') }" routerLinkActive="active" (contextmenu)="onRightClick($event, '/sourcing/6')">
          <a class="child-link" style="cursor: pointer !important">Master PO</a>
        </li>
        <li (click)=" navigateTo(2); navigateRoute('/sourcing/7')"
          [ngClass]="{ 'tab-active': getActivatedRoute('/sourcing/7') }" routerLinkActive="active" (contextmenu)="onRightClick($event, '/sourcing/7')">
          <a class="child-link" style="cursor: pointer !important">Service Agreement</a>
        </li>
      </ul>
    </li>
    <li [ngClass]="{ active: selectedId == 3 }">
      <div class="iocn-link cursor-pointer" (click)="toggleDropdpwnMenu($event)">
        <a>
          <i class="ri-inbox-archive-fill"></i>
          <span class="link_name">Procurement</span>
        </a>
        <i class="ri-arrow-down-s-line arrow"></i>
      </div>
      <ul class="sub-menu">
        <li
          (click)="$event.stopPropagation()"
          routerLinkActive="active"
          routerLink="/procurement/purchase"
          (contextmenu)="onRightClick($event, '/procurement/purchase')"
        >
          <a class = "child-link" style="cursor: pointer !important">Purchase</a>
        </li>
        <li (contextmenu)="onRightClick($event, '/procurement/advancepo')" (click)="$event.stopPropagation()" routerLinkActive="active" routerLink="/procurement/advancepo">
          <a class = "child-link" style="cursor: pointer !important">Advance PO</a>
        </li>
        <li (contextmenu)="onRightClick($event, '/procurement/newmrndetails')" (click)="$event.stopPropagation()" routerLinkActive="active" routerLink="/procurement/newmrndetails">
          <a class = "child-link" style="cursor: pointer !important">MRN Details</a>
        </li>
        <li (contextmenu)="onRightClick($event, '/procurement/pobulkstagemovement')" (click)="$event.stopPropagation()" routerLinkActive="active" routerLink="/procurement/pobulkstagemovement">
          <a class = "child-link" style="cursor: pointer !important">PO Bulk Stage Movement</a>
        </li>
      </ul>
      <ul class="sub-menu">
        <li routerLinkActive="active">
          <div class="iocn-link cursor-pointer" (click)="toggleDropdpwnMenu($event)">
            <a>
              <span class="link_name">Approve Supplier Invoice</span>
            </a>
            <i class="ri-arrow-down-s-line arrow"></i>
          </div>
          <ul class="sub-menu">
            <li (contextmenu)="onRightClick($event, '/procurement/supplierInvoice/dropship-invoices')" (click)="$event.stopPropagation()" routerLinkActive="active" routerLink="/procurement/supplierInvoice/dropship-invoices" >
              <a class = "child-link" style="cursor: pointer !important"  
                >Dropship Invoices</a
              >
            </li>
            <li (contextmenu)="onRightClick($event, '/procurement/supplierInvoice/onhold-invoices')"
              (click)="$event.stopPropagation()"
              routerLinkActive="active"
              routerLink="/procurement/supplierInvoice/onhold-invoices"
            >
              <a class = "child-link" style="cursor: pointer !important" routerLinkActive="active">Onhold Invoices</a>
            </li>
            <li (contextmenu)="onRightClick($event, '/procurement/supplierInvoice/drophship-pod')"
              (click)="$event.stopPropagation()"
              routerLinkActive="active"
              routerLink="/procurement/supplierInvoice/drophship-pod"
            >
              <a class = "child-link" style="cursor: pointer !important" routerLinkActive="active">Dropship POD</a>
            </li>
          </ul>
        </li>
      </ul>
      <ul class="sub-menu">
        <li (contextmenu)="onRightClick($event, '/procurement/approve-ld')" (click)="$event.stopPropagation()" routerLinkActive="active" routerLink="/procurement/approve-ld">
          <a class = "child-link" style="cursor: pointer !important" routerLinkActive="active">Approve LD</a>
        </li>
      </ul>
    </li>

    <!-- <li>
      <div class="iocn-link" (click)="toggleDropdpwnMenu($event)">
        <a >
          <i class="ri-stack-fill"></i>
          <span class="link_name">Invoices</span>
        </a>
        <i class="ri-arrow-down-s-line arrow"></i>
      </div>
      <ul class="sub-menu">
        <li><a >Bulk Invoicing</a></li>
        <li><a >Invoicing</a></li>
      </ul>
    </li> -->

    <li [ngClass]="{ active: selectedId == 4 }">
      <div class="iocn-link cursor-pointer" (click)="toggleDropdpwnMenu($event)">
        <a>
          <i class="ri-store-3-fill"></i>
          <span class="link_name">Warehouse</span>
        </a>
        <i class="ri-arrow-down-s-line arrow"></i>
      </div>
      <ul class="sub-menu">
        <li (contextmenu)="onRightClick($event, '/warehousemanagement/dashboard')" (click)="$event.stopPropagation()" routerLinkActive="active" routerLink="/warehousemanagement/dashboard">
          <a class = "child-link" style="cursor: pointer !important; font-weight: 600;">Warehouse</a>
        </li>
        <li>
          <div class="iocn-link cursor-pointer" (click)="toggleDropdpwnMenu($event)">
            <a>
              <span class="link_name">Inbounding </span>
            </a>
            <i class="ri-arrow-down-s-line arrow"></i>
          </div>
          <ul class="sub-menu">
            <li (contextmenu)="onRightClick($event, '/warehousemanagement/inbound')" (click)="$event.stopPropagation()" routerLinkActive="active" routerLink="/warehousemanagement/inbound">
              <a class = "child-link" style="cursor: pointer !important; font-weight: 600;">Inbounding</a>
            </li>
            <li (contextmenu)="onRightClick($event, '/warehousemanagement/mapsupplierinvoice')"
              (click)="$event.stopPropagation()"
              routerLinkActive="active"
              routerLink="/warehousemanagement/mapsupplierinvoice"
            >
              <a class = "child-link" style="cursor: pointer !important">Map Supplier Invoice</a>
            </li>
            <li (contextmenu)="onRightClick($event, '/warehousemanagement/mrn')" (click)="$event.stopPropagation()" routerLinkActive="active" routerLink="/warehousemanagement/mrn">
              <a class = "child-link" style="cursor: pointer !important">MRN</a>
            </li>
            <li (contextmenu)="onRightClick($event, '/warehousemanagement/inbounding')"
              (click)="$event.stopPropagation()"
              routerLinkActive="active"
              routerLink="/warehousemanagement/inbounding"
            >
              <a class = "child-link" style="cursor: pointer !important">Inbound</a>
            </li>
            <li (contextmenu)="onRightClick($event, '/warehousemanagement/read-barcode')" (click)="$event.stopPropagation()" routerLinkActive="active" routerLink="/warehousemanagement/read-barcode">
              <a class = "child-link" style="cursor: pointer !important">Read Barcode</a>
            </li>
          </ul>
        </li>
      </ul>
      <ul class="sub-menu">
        <li>
          <div class="iocn-link cursor-pointer" (click)="toggleDropdpwnMenu($event)">
            <a>
              <span class="link_name">Inventory</span>
            </a>
            <i class="ri-arrow-down-s-line arrow"></i>
          </div>
          <ul class="sub-menu">
            <li (contextmenu)="onRightClick($event, '/warehousemanagement/inventory')"
              (click)="$event.stopPropagation()"
              routerLinkActive="active"
              routerLink="/warehousemanagement/inventory"
            >
              <a class = "child-link" style="cursor: pointer !important; font-weight: 600;"><span>Inventory</span></a>
            </li>
            <li (contextmenu)="onRightClick($event, '/warehousemanagement/inventoryview')"
              (click)="$event.stopPropagation()"
              routerLinkActive="active"
              routerLink="/warehousemanagement/inventoryview"
            >
              <a class = "child-link" style="cursor: pointer !important">Inventory View</a>
            </li>
            <li
              (click)="$event.stopPropagation()"
              routerLinkActive="active"
              routerLink="/warehousemanagement/inFlightInventory"
              (contextmenu)="onRightClick($event, '/warehousemanagement/inFlightInventory')"
            >
              <a class = "child-link" style="cursor: pointer !important">In Flight Inventory</a>
            </li>
            <li (contextmenu)="onRightClick($event, '/warehousemanagement/inventorytransfer')"
              (click)="$event.stopPropagation()"
              routerLinkActive="active"
              routerLink="/warehousemanagement/inventorytransfer"
            >
              <a class = "child-link" style="cursor: pointer !important">Inventory Transfer</a>
            </li>
            <li (contextmenu)="onRightClick($event, '/warehousemanagement/bintransfer')"
              (click)="$event.stopPropagation()"
              routerLinkActive="active"
              routerLink="/warehousemanagement/bintransfer"
            >
              <a class = "child-link" style="cursor: pointer !important">Bin Transfer</a>
            </li>
            <li (contextmenu)="onRightClick($event, '/warehousemanagement/vmiupload')"
              (click)="$event.stopPropagation()"
              routerLinkActive="active"
              routerLink="/warehousemanagement/vmiupload"
            >
              <a class = "child-link" style="cursor: pointer !important">Upload VMI</a>
            </li>
            <li (contextmenu)="onRightClick($event, '/warehousemanagement/bindetails')"
              (click)="$event.stopPropagation()"
              routerLinkActive="active"
              routerLink="/warehousemanagement/bindetails"
            >
              <a class = "child-link" style="cursor: pointer !important">Bin Details Upload</a>
            </li>
          </ul>
        </li>
      </ul>
      <ul class="sub-menu">
        <li>
          <div class="iocn-link cursor-pointer" (click)="toggleDropdpwnMenu($event)">
            <a>
              <span class="link_name cursor-pointer">Outbounding</span>
            </a>
            <i class="ri-arrow-down-s-line arrow"></i>
          </div>
          <ul class="sub-menu">
            <li>
              <div class="iocn-link cursor-pointer" (click)="toggleDropdpwnMenu($event)">
                <a style="cursor: pointer !important;">
                  <span class="link_name">Picking</span>
                </a>
                <i class="ri-arrow-down-s-line arrow"></i>
              </div>
              <ul class="sub-menu">
                <li (contextmenu)="onRightClick($event, '/warehousemanagement/picking')"
                (click)="$event.stopPropagation()"
                routerLinkActive="active"
                routerLink="/warehousemanagement/picking"
              >
                <a class = "child-link" style="cursor: pointer !important; font-weight: 600;"><span>Picking</span></a>
              </li>
                <li (click)="$event.stopPropagation()" (contextmenu)="onRightClick($event, '/warehousemanagement/freshpicklist')"
                routerLinkActive="active"
                routerLink="/warehousemanagement/freshpicklist"><a class = "child-link" style="cursor: pointer !important" >Fresh Picklist</a></li>
                <li (click)="$event.stopPropagation()" (contextmenu)="onRightClick($event, '/warehousemanagement/returnpicklist')"
                routerLinkActive="active"
                routerLink="warehousemanagement/returnpicklist"><a class = "child-link" style="cursor: pointer !important" >Return Picklist</a></li>
              </ul>
            </li>
            <li (click)="$event.stopPropagation()" (contextmenu)="onRightClick($event, '/warehousemanagement/pack')"
            routerLinkActive="active"
            routerLink="/warehousemanagement/pack"><a class = "child-link" style="cursor: pointer !important" >Packing</a></li>
            <li (click)="$event.stopPropagation()" (contextmenu)="onRightClick($event, '/warehousemanagement/rts')"
            routerLinkActive="active"
            routerLink="/warehousemanagement/rts"><a class = "child-link" style="cursor: pointer !important" >Ready to Ship</a></li>
            <li (click)="$event.stopPropagation()" (contextmenu)="onRightClick($event, '/warehousemanagement/handoverlsp')"
            routerLinkActive="active"
            routerLink="/warehousemanagement/handoverlsp"><a class = "child-link" style="cursor: pointer !important" >Handover to LSP</a></li>
            <li (click)="$event.stopPropagation()" (contextmenu)="onRightClick($event, '/warehousemanagement/pack/list')"
            routerLinkActive="active"
            routerLink="/warehousemanagement/pack/list"><a class = "child-link" style="cursor: pointer !important" >Shipment Details</a></li>
          </ul>
        </li>
      </ul>
      <ul class="sub-menu">
        <li>
          <div class="iocn-link cursor-pointer" (click)="toggleDropdpwnMenu($event)">
            <a>
              <span class="link_name">Returns Management</span>
            </a>
            <i class="ri-arrow-down-s-line arrow"></i>
          </div>
          <ul class="sub-menu">
            <!-- <li (click)="$event.stopPropagation()" routerLinkActive="active" routerLink="/warehousemanagement/returns">
              <a class = "child-link" style="cursor: pointer !important">Returns</a>
            </li> -->
            <li (click)="$event.stopPropagation()" routerLinkActive="active" routerLink="/warehousemanagement/returns" (contextmenu)="onRightClick($event, '/warehousemanagement/returns')">
              <a class = "child-link" style="cursor: pointer !important">Supplier Identification</a>
            </li>
          </ul>
        </li>
      </ul>

      <ul class="sub-menu">
        <li>
          <div class="iocn-link cursor-pointer" (click)="toggleDropdpwnMenu($event)">
            <a>
              <span class="link_name">STN </span>
            </a>
            <i class="ri-arrow-down-s-line arrow"></i>
          </div>
          <ul class="sub-menu">
            <li (contextmenu)="onRightClick($event, '/warehousemanagement/stn')" (click)="$event.stopPropagation()" routerLinkActive="active" routerLink="/warehousemanagement/stn">
              <a class = "child-link" style="cursor: pointer !important">Create STN</a>
            </li>
            <li (contextmenu)="onRightClick($event, '/warehousemanagement/in-transit')"
              (click)="$event.stopPropagation()"
              routerLinkActive="active"
              routerLink="/warehousemanagement/in-transit"
            >
              <a class = "child-link" style="cursor: pointer !important">STN In Transit</a>
            </li>
            <!-- <li (click)="$event.stopPropagation()" routerLinkActive="active" routerLink="/warehousemanagement/returns">
              <a class = "child-link" style="cursor: pointer !important">Read Barcode</a>
            </li> -->
            <!-- <li
              (click)="$event.stopPropagation()"
              routerLinkActive="active"
              routerLink="/warehousemanagement/read-barcode"
            >
              <a class = "child-link" style="cursor: pointer !important">View Product Details</a>
            </li> -->
          </ul>
        </li>
      </ul>

      <ul class="sub-menu">
        <li>
          <div class="iocn-link cursor-pointer" (click)="toggleDropdpwnMenu($event)">
            <a>
              <span class="link_name">WMS SAAS</span>
            </a>
            <i class="ri-arrow-down-s-line arrow"></i>
          </div>
          <ul class="sub-menu">
            <li (contextmenu)="onRightClick($event, '/warehousemanagement/wmsSaasmrn/1')"
              (click)="$event.stopPropagation()"
              routerLinkActive="active"
              [routerLink]="['/warehousemanagement/wmsSaasmrn', 1]"
              (click) = "navigateToUrl('/warehousemanagement/wmsSaasmrn/1')"
            >
              <a class = "child-link" style="cursor: pointer !important">WMS SAAS MRN</a>
            </li>
            <!-- <li
              (click)="$event.stopPropagation()"
              routerLinkActive="active"
              [routerLink]="['/warehousemanagement/wmsSaasmrn', 1]"
            >
              <a class = "child-link" style="cursor: pointer !important">WMS Saas Mrn</a>
            </li> -->
            <li (contextmenu)="onRightClick($event, '/warehousemanagement/wmsSaasmrn/2')"
              (click)="$event.stopPropagation()"
              routerLinkActive="active"
              [routerLink]="['/warehousemanagement/wmsSaasmrn', 2]"
              (click) = "navigateToUrl('/warehousemanagement/wmsSaasmrn/2')"
            >
              <a class = "child-link" style="cursor: pointer !important">WMS SAAS Outbound</a>
            </li>
            <li (contextmenu)="onRightClick($event, '/warehousemanagement/wmsSaasmrn/1/upload')"
              (click)="$event.stopPropagation()"
              routerLinkActive="active"
              routerLink="warehousemanagement/wmsSaasmrn/1/upload"
            >
              <a class = "child-link" style="cursor: pointer !important">Upload Status</a>
            </li>
          </ul>
        </li>
      </ul>

      <ul class="sub-menu">
        <li>
          <div class="iocn-link cursor-pointer" (click)="toggleDropdpwnMenu($event)">
            <a>
              <span class="link_name">Bulk GRN </span>
            </a>
            <i class="ri-arrow-down-s-line arrow"></i>
          </div>
          <ul class="sub-menu">
            <li
              (click)="$event.stopPropagation()"
              routerLinkActive="active"
              [routerLink]="['/warehousemanagement/bulkGrn']"
              (contextmenu)="onRightClick($event, '/warehousemanagement/bulkGrn')"
            >
              <a class = "child-link" style="cursor: pointer !important">Bulk GRN Upload</a>
            </li>
            <li
              (click)="$event.stopPropagation()"
              routerLinkActive="active"
              [routerLink]="['/warehousemanagement/view-grn-requests']"
              (contextmenu)="onRightClick($event, '/warehousemanagement/view-grn-requests')"
            >
              <a class = "child-link" style="cursor: pointer !important">View Bulk GRN Request</a>
            </li>
          </ul>
        </li>
      </ul>

      <ul class="sub-menu">
        <li>
          <div class="iocn-link cursor-pointer" (click)="toggleDropdpwnMenu($event)">
            <a>
              <span class="link_name"> Bulk Invoice Processing </span>
            </a>
            <i class="ri-arrow-down-s-line arrow"></i>
          </div>
          <ul class="sub-menu">
            <!-- <li (click)="$event.stopPropagation()" routerLinkActive="active" [routerLink]="['/warehousemanagement']">
              <a style=" cursor: pointer; font-weight: 600;"><span>Bulk Invoice Processing</span></a>
            </li> -->
            <li
              (click)="$event.stopPropagation()"
              routerLinkActive="active"
              [routerLink]="['/warehousemanagement/view-batches']"
              (contextmenu)="onRightClick($event, '/warehousemanagement/view-batches')"
            >
              <a class = "child-link" style="cursor: pointer !important">View Batches</a>
            </li>
            <li
              (click)="$event.stopPropagation()"
              routerLinkActive="active"
              [routerLink]="['/warehousemanagement/process-bulk-invoice']"
              (contextmenu)="onRightClick($event, '/warehousemanagement/process-bulk-invoice')"
            >
              <a class = "child-link" style="cursor: pointer !important"> Process Bulk Invoice </a>
            </li>
            <li
              (click)="$event.stopPropagation()"
              routerLinkActive="active"
              [routerLink]="['/warehousemanagement/shipped-request-status']"
              (contextmenu)="onRightClick($event, '/warehousemanagement/shipped-request-status')"
            >
              <a class = "child-link" style="cursor: pointer !important"> Shipped Request Status </a>
            </li>
            <li
              (click)="$event.stopPropagation()"
              routerLinkActive="active"
              [routerLink]="['/warehousemanagement/bulk-pod-upload']"
              (contextmenu)="onRightClick($event, '/warehousemanagement/bulk-pod-upload')"
            >
              <a class = "child-link" style="cursor: pointer !important"> Bulk POD Upload </a>
            </li>
            <li
              (click)="$event.stopPropagation()"
              routerLinkActive="active"
              [routerLink]="['/warehousemanagement/bulk-pod-status']"
              (contextmenu)="onRightClick($event, '/warehousemanagement/bulk-pod-status')"
            >
              <a class = "child-link" style="cursor: pointer !important"> Bulk POD Status </a>
            </li>
            <li
              (click)="$event.stopPropagation()"
              routerLinkActive="active"
              [routerLink]="['/warehousemanagement/bulk-cancellation']"
              (contextmenu)="onRightClick($event, '/warehousemanagement/bulk-cancellation')"
            >
              <a class = "child-link" style="cursor: pointer !important"> Bulk cancellation </a>
            </li>
            <li
              (click)="$event.stopPropagation()"
              routerLinkActive="active"
              [routerLink]="['/warehousemanagement/bulk-plant-config']"
              (contextmenu)="onRightClick($event, '/warehousemanagement/bulk-plant-config')"
            >
              <a class = "child-link" style="cursor: pointer !important"> Bulk plant config </a>
            </li>
            <li
              (click)="$event.stopPropagation()"
              routerLinkActive="active"
              [routerLink]="['/warehousemanagement/picklist-request-status/picklist']"
              (contextmenu)="onRightClick($event, '/warehousemanagement/picklist-request-status/picklist')"
            >
              <a class = "child-link" style="cursor: pointer !important"> Picklist Request Status </a>
            </li>
            <li
              (click)="$event.stopPropagation()"
              routerLinkActive="active"
              [routerLink]="['/warehousemanagement/bulk-cancel-reuqest-status']"
              (contextmenu)="onRightClick($event, '/warehousemanagement/bulk-cancel-reuqest-status')"
            >
              <a class = "child-link" style="cursor: pointer !important"> Bulk Cancel Request Status </a>
            </li>
            <li
              (click)="$event.stopPropagation()"
              routerLinkActive="active"
              [routerLink]="['/warehousemanagement/retry-invoicing']"
              (contextmenu)="onRightClick($event, '/warehousemanagement/retry-invoicing')"
            >
              <a class = "child-link" style="cursor: pointer !important"> Retry Invoicing </a>
            </li>
            <li
              (click)="$event.stopPropagation()"
              routerLinkActive="active"
              [routerLink]="['/warehousemanagement/asn-request-status/asn']"
              (contextmenu)="onRightClick($event, '/warehousemanagement/asn-request-status/asn')"
            >
              <a class = "child-link" style="cursor: pointer !important"> ASN Request Status </a>
            </li>
            <li
              (click)="$event.stopPropagation()"
              routerLinkActive="active"
              [routerLink]="['/warehousemanagement/delivered-request-status']"
              (contextmenu)="onRightClick($event, '/warehousemanagement/delivered-request-status')"
            >
              <a class = "child-link" style="cursor: pointer !important"> Delivered Request Status </a>
            </li>
            <li
              (click)="$event.stopPropagation()"
              routerLinkActive="active"
              [routerLink]="['/warehousemanagement/bulk-invoicing-status']"
              (contextmenu)="onRightClick($event, '/warehousemanagement/bulk-invoicing-status')"
            >
              <a class = "child-link" style="cursor: pointer !important"> Bulk Invoicing Status </a>
            </li>
          </ul>
        </li>
      </ul>
    </li>

    <!-- <li>
      <div class="iocn-link" (click)="toggleDropdpwnMenu($event)">
        <a >
          <img class="digi-icon" src="/assets/img/digimro.svg" />
          <span class="link_name">Digi MRO</span>
        </a>
        <i class="ri-arrow-down-s-line arrow"></i>
    <li [ngClass]="{ active: selectedId == 4 }" class="nav-item" (click)="navigateTo('procurement/purchase', 4)">
      <a routerLink="/procurement/purchase" class="nav-link">Procurement<span class="ri-inbox-archive-fill"></span></a>
      <div style="width: 180px;" class="navsubmenu">
        <div class="navsubmenuwrap">
          <ul>
            <li (click)="$event.stopPropagation(); navigateTo('procurement/purchase', 4)" style="cursor: pointer">
              <a routerLink="/procurement/purchase" class="nav-link">Order Management</a>
            </li>
            <li (click)="$event.stopPropagation(); navigateTo('procurement/purchase', 4)">
              <a routerLink="/procurement/purchase" class="reset">Purchase</a>
            </li>
            <li (click)="$event.stopPropagation(); navigateTo('procurement/advancepo', 4)">
              <a routerLink="/procurement/advancepo" class="reset">Advance PO</a>
            </li>
            <li (click)="$event.stopPropagation(); navigateTo('procurement/newmrndetails', 4)">
              <a routerLink="/procurement/newmrndetails" class="reset">MRN Details</a>
            </li>
            <li (click)="$event.stopPropagation(); navigateTo('procurement/pobulkstagemovement', 4)">
              <a routerLink="/procurement/pobulkstagemovement" class="reset">PO Bulk Stage Movement</a>
            </li>
            <ul>
              <li (click)="$event.stopPropagation();" style="cursor: pointer">
                <a (click)="navigateTo('/procurement/supplierInvoice', 7)" class="nav-link">Approve Supplier Invoice</a>
              </li>
              <li>
                <ul>
                  <li (click)="$event.stopPropagation();" style="cursor: pointer;margin-left: 18px;">
                    <a (click)="navigateTo('/procurement/supplierInvoice', 9)" class="nav-link">Dropship
                      Invoices</a><br /><br />
                    <a (click)="navigateTo('/procurement/supplierInvoice/onhold-invoices', 10)" class="nav-link">On Hold
                      Invoices</a><br /><br />
                    <a (click)="navigateTo('/procurement/supplierInvoice/drophship-pod', 11)" class="nav-link">Dropship
                      POD</a>
                  </li>
                </ul>
              </li>
            </ul>
            <li (click)="$event.stopPropagation(); navigateTo('procurement/approve-ld', 4)">
              <a routerLink="/procurement/approve-ld" class="reset">Approve LD</a>
            </li>
          </ul>
        </div>
      </div>
      <ul class="sub-menu ">
        <li (click)="$event.stopPropagation();"><a >Digi MRO MRN</a></li>
        <li (click)="$event.stopPropagation();"><a >Digi MRO Outbound</a></li>
        <li (click)="$event.stopPropagation();"><a >Upload Status</a></li>
      </ul>
    </li> -->

    <li [ngClass]="{ active: selectedId == 5 }">
      <div class="iocn-link cursor-pointer" (click)="toggleDropdpwnMenu($event)">
        <a>
          <i class="ri-cpu-line"></i>
          <span class="link_name">Configurations</span>
        </a>
        <i class="ri-arrow-down-s-line arrow"></i>
      </div>
      <ul class="sub-menu">
        <li (click)="$event.stopPropagation()" routerLinkActive="active" [routerLink]="['/settings']" (contextmenu)="onRightClick($event, '/settings')">
          <a class = "child-link" style="cursor: pointer !important">Brand Warehouse Mapping</a>
        </li>
        <li (click)="$event.stopPropagation()" routerLinkActive="active" [routerLink]="['/settings']" (contextmenu)="onRightClick($event, '/settings')">
          <a class = "child-link" style="cursor: pointer !important">MSN Warehouse Mapping</a>
        </li>
      </ul>
    </li>

    <!-- EMS Module -->
    <li [ngClass]="{ active: selectedId == 6 }">
      <div class="iocn-link cursor-pointer" (click)="toggleDropdpwnMenu($event)">
        <a>
          <i class="ri-stack-fill"></i>
          <span class="link_name">Invoices</span>
        </a>
        <i class="ri-arrow-down-s-line arrow"></i>
      </div>
      <ul class="sub-menu">
        <li (click)="$event.stopPropagation()" routerLinkActive="active" routerLink="/invoice/enterprise" (contextmenu)="onRightClick($event, '/invoice/enterprise')" >
          <a class = "child-link" style="cursor: pointer !important">Enterprise</a>
        </li>
        <li (click)="$event.stopPropagation()" >
          <a [href]="EMSWEBURL" target="_blank" style="cursor: pointer !important; color: #363636 !important">Packed</a>
        </li>
      </ul>
    </li>

  </ul>
</div>

<!-- Custom Context Menu -->
<div *ngIf="showContextMenu" [ngStyle]="{top: contextMenuPosition.y, left: contextMenuPosition.x}"
  class="custom-context-menu">
  <div (click)="openInNewTab()">Open link in new tab</div>
  <div (click)="openInNewWindow()">Open link in new window</div>
  <!-- <span class = "oneline-border"></span> -->
  <div (click)="copyLinkAddress()">Copy link address</div>
</div>
<!-- old codr -->

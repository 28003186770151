import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { MsalService } from '@azure/msal-angular';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(
    private msalService: MsalService
  ) {
    if (localStorage.getItem('user')) {
      this.saveUserDetails(JSON.parse(localStorage.getItem('user')));
    }
  }

  getValue(key: string): string {
    let value = JSON.parse(localStorage.getItem('user'));
    return value ? value[key] : 'Guest';
  }

  signOut() {
    localStorage.removeItem('user');
    localStorage.removeItem('authToken');
    localStorage.removeItem('countryList');
    localStorage.removeItem('selectedCountryName');
    localStorage.removeItem('selectedCountryCode');
    localStorage.removeItem('selectedCountryId');
    localStorage.removeItem('idUser');
    localStorage.removeItem('idBranch');
    localStorage.removeItem('idCompany');
    localStorage.removeItem('token');
    sessionStorage.clear();
    window.location.reload();
  }

  saveUserDetails(user) {
    localStorage.setItem('user', JSON.stringify(user));
  }

  isLogin(): boolean {
    return localStorage.getItem('user') ? true : false;
  }

  isAuthenticated(): boolean {
    return localStorage.getItem('authToken') ? true : false;
  }

  setCountryList(countryList) {
    localStorage.setItem('countryList', JSON.stringify(countryList));
  }
  setSelectedCountryCode(selectedCountryCode) {
    localStorage.setItem('selectedCountryCode', selectedCountryCode);
  }

  setSelectedCountryName(selectedCountryName) {
    localStorage.setItem('selectedCountryName', selectedCountryName);
  }

  setSelectedCountryID(selectedCountryId) {
    localStorage.setItem('selectedCountryId', selectedCountryId);
  }

  getCountryList() {
    return JSON.parse(localStorage.getItem('countryList'));
  }

  getIsMobileView() {
    return JSON.parse(localStorage.getItem('isMobileView'));
  }

  getSelectedCountryCode() {
    return JSON.parse(localStorage.getItem('selectedCountryCode'));
  }
  getSelectedCountryName() {
    return localStorage.getItem('selectedCountryName');
  }
  checkMediaScreen() {
    // Use the window reference: this.windowRef
    var ms = window.matchMedia("(max-width: 767px)");
    if (ms.matches) { // If media query matches
      return true;
    } else {
      return false;
    }
  }
}

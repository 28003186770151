import { ConfirmationComponent } from './../confirmation/confirmation.component';
import { Injectable } from '@angular/core';
import { MatSnackBar, MatDialog, MatDialogRef } from '@angular/material';
import { ToasterComponent } from '../toaster/toaster.component';
import { prepareSyntheticListenerFunctionName } from '@angular/compiler/src/render3/util';

@Injectable({
  providedIn: 'root',
})
export class UtilService {
  private confirmationRef: MatDialogRef<ConfirmationComponent>;

  constructor(private toaster: MatSnackBar, private dialog: MatDialog) {}

  openToaster(data) {
    this.toaster.openFromComponent(ToasterComponent, {
      verticalPosition: 'top',
      horizontalPosition: 'center',
      duration: 5000,
      data: { message: data.message },
      panelClass: this.getPanelClass(data.messageType),
    });
  }

  closeToaster() {
    this.toaster.dismiss();
  }

  openConfirmation(data) {
    this.confirmationRef = this.dialog.open(ConfirmationComponent, {
      width: '30%',
      autoFocus: false,
      data: { title: data.title, message: data.message, note: data.note ? data.note : null },
    });
    return this.confirmationRef;
  }

  closeConfirmation() {
    this.confirmationRef.close();
  }

  private getPanelClass(messageType): string {
    let returnValue = 'tpanel-info';
    switch (messageType) {
      case 'SUCCESS': {
        returnValue = 'tpanel-success';
        break;
      }
      case 'ERROR': {
        returnValue = 'tpanel-error';
        break;
      }
      case 'WARNING': {
        returnValue = 'tpanel-warning';
        break;
      }
    }
    return returnValue;
  }

  cleanseResponse(response, successFlag?) {
    let returnValue = null;
    const status = response['status'];
    switch (response['status']) {
      case 'SUCCESS': {
        if (successFlag) {
          this.openToaster({ message: response['message'], messageType: status });
        }
        returnValue = response['data'];
        break;
      }
      case 'WARNING': {
        this.openToaster({ message: response['message'], messageType: status });
        break;
      }
      case 'ERROR': {
        this.openToaster({ message: response['message'], messageType: status });
        break;
      }
      default: {
        this.openToaster({ message: 'Something went wrong', messageType: 'ERROR' });
      }
    }
    return returnValue;
  }
}

<section class="stockTransferNoteWrapper">

  <section class="stnListing stn-in-transit-table-overflow">
    <table mat-table [dataSource]="dataSource">
      <div *ngIf="receivedData.invoice">
        <ng-container matColumnDef="invoiceno">
          <th mat-header-cell *matHeaderCellDef>Invoice No</th>
          <td mat-cell *matCellDef="let element"><a>{{ element.invoiceNumber}}</a></td>
        </ng-container>

        <ng-container matColumnDef="invoiceurl">
          <th mat-header-cell *matHeaderCellDef>Invoice Url</th>
          <td mat-cell *matCellDef="let element"><u (click)="onOpenInvoiceUrl(element.invoiceUrl)">{{ element.invoiceUrl
              }}</u></td>
        </ng-container>

        <ng-container matColumnDef="packedQty">
          <th mat-header-cell *matHeaderCellDef>Packed Qty</th>
          <td mat-cell *matCellDef="let element">{{ element.packedQuantity }}</td>
        </ng-container>

        <ng-container matColumnDef="awb">
          <th mat-header-cell *matHeaderCellDef>AWB</th>
          <td mat-cell *matCellDef="let element">{{element.awb}}</td>
        </ng-container>

        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef>Status</th>
          <td mat-cell *matCellDef="let element">{{element.status}}</td>
        </ng-container>
      </div>
      <div *ngIf="!receivedData.invoice">
        <ng-container matColumnDef="invoiceno">
          <th mat-header-cell *matHeaderCellDef>Product MSN</th>
          <td mat-cell *matCellDef="let element"><a>{{ element.productMsn}}</a></td>
        </ng-container>

        <ng-container matColumnDef="invoiceurl">
          <th mat-header-cell *matHeaderCellDef>Original ItemRef</th>
          <td mat-cell *matCellDef="let element">{{ element.originalItemRef }}</td>
        </ng-container>

        <ng-container matColumnDef="packedQty">
          <th mat-header-cell *matHeaderCellDef>Outward ItemRef</th>
          <td mat-cell *matCellDef="let element">{{ element.outwardItemRef }}</td>
        </ng-container>

        <ng-container matColumnDef="awb">
          <th mat-header-cell *matHeaderCellDef>Inward ItemRef</th>
          <td mat-cell *matCellDef="let element">{{element.inwardItemRef}}</td>
        </ng-container>

        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef>Quantity</th>
          <td mat-cell *matCellDef="let element">{{element.quantity}}</td>
        </ng-container>
      </div>

      <tr mat-header-row *matHeaderRowDef="displayedColumns,sticky:true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </section>
</section>
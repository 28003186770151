import { Component, Inject, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { MatTableDataSource } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';
import { DataService } from 'src/app/util/services/data.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { SourcingService } from 'src/app/sourcing/services/sourcing.service';
import { SourcingUtilService } from 'src/app/sourcing/services/sourcing-util.service';
import { UserService } from 'src/app/user.service';
@Component({
  selector: 'app-inventorymsn-detail-dialog',
  templateUrl: './inventorymsn-detail-dialog.component.html',
  styleUrls: ['./inventorymsn-detail-dialog.component.scss']
})
export class InventorymsnDetailDialogComponent implements OnInit {

  
  readonly WMSURL = environment.WMSURL;
  dataSource = new MatTableDataSource<any>();
  displayedColumns = ['transactionType', 'docType', 'docNumber', 'WarehouseId', 'warehouseName', 'mrnDate', 'docQty', 'spoItem', 'itemRef','docValue','user'];
  warehouseList = [];
  productMSN: string;
  productName: any;
  warehouseId: number;
  mrnDate: any;
  constructor(private dialogRef: MatDialogRef<InventorymsnDetailDialogComponent>,
    private toastr: ToastrService,
    private dataService: DataService,
    private sourcingService : SourcingService,
    public sourcingUtil: SourcingUtilService,
    public userService : UserService,
    @Inject(MAT_DIALOG_DATA) public productMsnDetailData: any){
    //this.getWarehouseList();
    this.fetchWarehouses();
    this.productMSN = this.productMsnDetailData.productMsn;
    this.productName = this.productMsnDetailData.productName;
    this.warehouseId= this.productMsnDetailData.warehouseId;
  }

  // getWarehouseList() {
  //   this.dataService.get(this.WMSURL + 'warehouse/search').subscribe((data) => {
  //     this.warehouseList = data['warehouses'];
  //   });
  // }
  
  fetchWarehouses() {
    let countryCode1 = this.userService.getSelectedCountryCode();
    const country = { countryCode: countryCode1 };
    this.sourcingService.getWarehouses(country).subscribe((response) => {
      let data = this.sourcingUtil.cleanseResponse(response, false);
      if (data) {
        this.warehouseList = data;
      }
    });
  }

  ngOnInit() {
    let request ={
      productMsn : this.productMSN,
      warehouseId : this.warehouseId
    }
    this.dataService.post(this.WMSURL + 'reports/fetchlogs',request).subscribe((list: any) => {
            this.dataSource.data= list['dailyReportLogs'];
 for(var i=0;i<list['dailyReportLogs'].length;i++){
   
    this.dataSource.data[i].transaction_date= list['dailyReportLogs'][i]['transaction_date'].substring(0,10);

 }
    }),
    (error)=>{

    }
  }
  display(a:any){
    if(a){
    let regex = a.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
    return regex;
    }
    else return 0;
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DashboardRoutingModule } from './dashboard-routing.module';
// import { StructureSideNavComponent } from './structure-side-nav/structure-side-nav.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { StructureSideNavComponent } from '../structure/structure-side-nav/structure-side-nav.component';
import { DesignComponent } from '../structure/design/design.component';
import { StructureTopNavComponent } from '../structure/structure-top-nav/structure-top-nav.component';
import { StructureSideNavTogglerComponent } from '../structure/structure-side-nav-toggler/structure-side-nav-toggler.component';
import { PageContentComponent } from '../structure/page-content/page-content.component';
import { MatExpansionModule, MatButtonModule, MatFormFieldModule, MatInputModule, MatSelectModule, MatTabsModule, MatTooltipModule, MatProgressBarModule, MatIconModule, MatDividerModule, MatDatepickerModule, MatNativeDateModule, MatRadioModule, MatCheckboxModule, MAT_DATE_LOCALE, DateAdapter, MAT_DATE_FORMATS, MatSlideToggleModule, MatAutocompleteModule, MatMenuModule, MatDialogModule } from '@angular/material';


@NgModule({
  declarations: [
    StructureSideNavComponent,
    DesignComponent,
    StructureTopNavComponent,
    StructureSideNavTogglerComponent,
    PageContentComponent
  ],
  imports: [
    CommonModule,
    DashboardRoutingModule,
    MatSidenavModule,
    MatExpansionModule,
    MatButtonModule,
    MatTabsModule,
    MatTooltipModule,
    MatSelectModule,
    MatProgressBarModule,
    MatInputModule,
    MatFormFieldModule,
    MatIconModule,
    MatDividerModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatCheckboxModule,
    MatRadioModule,
    MatSlideToggleModule,
    MatAutocompleteModule,
    MatMenuModule,
    MatFormFieldModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    MatInputModule,
    MatDividerModule,

  ],
  exports: [
    StructureSideNavComponent,
    DesignComponent,
    StructureTopNavComponent,
    StructureSideNavTogglerComponent,
    PageContentComponent
  ]
})
export class DashboardModule { }
